<template lang="pug">
  aiq-card(v-if="!keepChartOnly")
    h2
      .select-box-container(v-if="!breakdown")
        aiq-select(placeholder="Choose bucket to filter with"
                   v-model="internalBucket"
                   value-key='value'
                   @change="onBucketFilterChange($event)"
                   size="small")
         aiq-option(v-for="option in BucketOptions"
                :key="option.value"
                :label="option.label"
                :value="option")
      | {{ getTitle() }}
      aiq-tooltip(v-if="chartConfig.helpText" placement="right")
        template(v-slot:content)
          div(v-html="$filters.sanitize($t(`${chartConfig.helpText}`, `${chartConfig.helpText}`))")
        i.iq-ico-explanation.help-icon
      aiq-button.export-chart(@click="exportChart")
        i.iq-ico-export
    component(:is="visualization"
              :report="getChartingData()",
              :graphParams="chartConfig.graphParams"
              :lines="chartLines",
              :linesColor="chartConfig.linesColor"
              :rightPadding="chartConfig.rightPadding"
              :leftPadding="chartConfig.leftPadding"
              :maxTickLabelCount="chartConfig.maxTickLabelCount"
              :grouped="grouped"
              :downloadWithTime="downloadWithTime"
              :yLabel="yLabel"
              :yLabelPosition="yLabelPosition"
              :y2Label="y2Label"
              :y2LabelPosition="y2LabelPosition"
              :xLabel="xLabel"
              :xLabelPosition="xLabelPosition"
              :formatXTick="chosenFormatXTick"
              :rotateXTick="rotateXTick"
              :formatYTick="formatYTick"
              :formatY2Tick="formatY2Tick"
              :yScaleMin="yScaleMin"
              :yScaleMax="yScaleMax"
              :y2ScaleMin="y2ScaleMin"
              :y2ScaleMax="y2ScaleMax"
              :y2Color="y2Color"
              :displayValues="displayValues"
              :yTickValues="yTickValues"
              :height="height"
              :margin="margin"
              :colors="heatMapColors"
              :tooltipsConfig="heatmapTooltipsConfig"
              :tooltipsClassname="heatmapTooltipsClassname"
              :shouldShowTotalOnTooltip="shouldShowTotalOnTooltip"
              :axisRotated="axisRotated"
              :barWidth="barWidth"
              @onChartStatus='onChartStatus($event)'
              @onLineClick='onLineClickInChart($event)')
    p.footnote(v-if="shouldHandlePartialDates") * Indicates that the date range includes partial weeks or months.
  .div(v-else)
    component(:is="visualization"
              :report="getChartingData()",
              :graphParams="chartConfig.graphParams"
              :lines="chartLines",
              :linesColor="chartConfig.linesColor"
              :grouped="grouped"
              :downloadWithTime="downloadWithTime"
              :yLabel="yLabel"
              :yLabelPosition="yLabelPosition"
              :y2Label="y2Label"
              :y2LabelPosition="y2LabelPosition"
              :xLabel="xLabel"
              :xLabelPosition="xLabelPosition"
              :formatXTick="chosenFormatXTick"
              :rotateXTick="rotateXTick"
              :formatYTick="formatYTick"
              :formatY2Tick="formatY2Tick"
              :yScaleMin="yScaleMin"
              :yScaleMax="yScaleMax"
              :y2ScaleMin="y2ScaleMin"
              :y2ScaleMax="y2ScaleMax"
              :y2Color="y2Color"
              :keepChartOnly="keepChartOnly"
              :displayValues="displayValues"
              :yTickValues="yTickValues"
              :height="height"
              :margin="margin"
              :colors="heatMapColors"
              :tooltipsConfig="heatmapTooltipsConfig"
              :tooltipsClassname="heatmapTooltipsClassname"
              :shouldShowTotalOnTooltip="shouldShowTotalOnTooltip"
              :axisRotated="axisRotated"
              :barWidth="barWidth"
              @onChartStatus='onChartStatus($event)'
              @onLineClick='onLineClickInChart($event)')
    p.footnote(v-if="shouldHandlePartialDates") * Indicates that the date range includes partial weeks or months.
</template>

<script>
import { downloadAsFile } from '@/libs';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import some from 'lodash/some';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';

const DAY_RANGE = 6;
const LOAD_METRICS_DEBOUNCE_TIME = 100;

export default {
  name: 'chart',
  props: {
    chartConfig: {
      required: true,
      type: Object,
    },
    BucketOptions: {
      type: Array,
      default: () => [
        // {
        //   value: 'hour',
        //   label: 'Hourly',
        //   xAxisFormat: value => moment(value).format('MMM DD ha'),
        // },  // Hourly bucket should be hidden  till ETL support is added
        {
          value: 'day',
          label: 'Daily',
          isDefault: true,
          xAxisFormat: value => moment(value).format('MMM DD'),
        },
        {
          value: 'week',
          label: 'Weekly',
          xAxisFormat: value => {
            const start = moment(value);
            const end = moment(value).add(DAY_RANGE, 'days');
            const format = 'MMM DD';
            return `${start.format(format)} - ${end.format(format)}`;
          },
        },
        {
          value: 'month',
          label: 'Monthly',
          xAxisFormat: value => moment(value).format('MMM'),
        },
      ],
      // TODO (akshay) get from a config
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
    filterResponseFields: {
      type: Array,
      default: () => [],
    },
    preLoadedchartData: {
      type: Object,
      default: () => ({}),
    },
    breakdown: {
      required: false,
      type: String,
    },
    filters: {
      type: Array,
    },
    formatXTick: {
      type: Function,
    },
    formatYTick: {
      type: Function,
    },
    rotateXTick: {
      type: Number,
    },
    formatY2Tick: {
      type: Function,
    },
    getTitleFromResponse: {
      type: Function,
    },
    combineResponseConfigTitles: {
      type: Function,
    },
    yLabel: {
      type: String,
    },
    yLabelPosition: {
      type: String,
      default: 'outer-top',
    },
    y2Label: {
      type: String,
    },
    y2LabelPosition: {
      type: String,
      default: 'outer-top',
    },
    xLabel: {
      type: String,
    },
    xLabelPosition: {
      type: String,
      default: 'outer-right',
    },
    grouped: {
      type: Boolean,
      default: () => false,
    },
    downloadWithTime: {
      type: Boolean,
      default: () => false,
    },
    keepChartOnly: {
      type: Boolean,
      default: () => false,
    },
    deriveLinesFromResponse: {
      type: Boolean,
      default: () => false,
    },
    yScaleMin: {
      type: Number,
    },
    yScaleMax: {
      type: Number,
    },
    y2ScaleMin: {
      type: Number,
      default: 0,
    },
    y2ScaleMax: {
      type: Number,
    },
    y2Color: {
      type: String,
      default: '#446FB5',
    },
    funnelOptions: {
      type: Object,
    },
    displayValues: {
      type: String,
      default: () => 'max',
      validator: value => ['all', 'max', 'min', 'none'].includes(value),
    },
    yTickValues: {
      type: Array,
    },
    height: {
      type: Number,
      default: () => 310, // eslint-disable-line
    },
    margin: {
      type: Object,
    },
    heatMapColors: {
      type: Array,
      default: () => ['#FFF2DB', '#FFE9C2', '#FFDB9C', '#FFCD75', '#FFBE50'],
    },
    heatmapTooltipsConfig: {
      type: Array,
      default: () => [],
    },
    heatmapTooltipsClassname: {
      type: String,
      default: '',
    },
    visualization: {
      type: String,
      default: 'aiq-chart',
    },
    reportFileName:{
      type: String,
      default: '',
    },
    getOriginalData: {
      type: Function,
    },
    useDebounceForLoadMetrics: {
      type: Boolean,
      default: false,
    },
    shouldShowTotalOnTooltip: {
      type: Boolean,
      default: false
    },
    filteredExportColumns: {
      type: Array,
      default: []
    },
    relabeledExportHeaders: {
      type: Array
    },
    shouldHandlePartialDates: {
      type: Boolean,
      default: false
    },
    axisRotated: {
      type: Boolean,
      default: false
    },
    barWidth: {
      type: Number,
      default: () => null
    },
    shouldChangeXLabels: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    breakdown(value, oldValue) {
      if (!oldValue || value === oldValue) {
        return;
      }
      this.loadMetric();
    },
    dateRange(value, oldValue = []) {
      const [nStart, nEnd] = value;
      if (nStart && nEnd && nStart == nEnd) {
        return;
      }
      const [start, end] = oldValue;
      if (!start && end) {
        return;
      }
      if (!this.BucketOptions.find(o => o.value === this.internalBucket.value)) {
        this.onBucketFilterChange(this.BucketOptions.find(o => o.isDefault));
      } else {
        if (this.useDebounceForLoadMetrics) {
          this.debouncedLoadMetric(); // Call the debounced version if the useDebounceForLoadMetrics prop is true
        } else {
          this.loadMetric(); // Call the regular function if debounce is not needed
        }
      }
    },
    filters: function watchFilters() {
      this.loadMetric();
    },
    filterResponseFields(value, oldValue) {
      if (isEmpty(oldValue) || value === oldValue) {
        return;
      }
      this.filterChartData();
    },
  },
  data() {
    return {
      chartData: {},
      chartDataCopy: {},
      internalBucket: this.BucketOptions.find(({ isDefault }) => isDefault),
      colors: [],
    };
  },
  emits: ['forwardedClickedDataPoint', 'onChartStatus', 'onChartDataChange', 'onChartBreakdownChange', 'onXLabelsChange'],
  computed: {
    chosenBreakdown() {
      return this.breakdown || this.internalBucket.value;
    },
    chosenFormatXTick() {
      return (!this.breakdown && !this.formatXTick) ? this.internalBucket.xAxisFormat : this.formatXTick;
    },
    chartLines() {
      return this.localizeChartLines(this.chartConfig?.lines || []);
    }
  },
  mounted() {
    if (isEmpty(this.preLoadedchartData)) {
      this.loadMetric();
    }
    this.debouncedLoadMetric = _.debounce(this.loadMetric, LOAD_METRICS_DEBOUNCE_TIME);
  },
  methods: {
    getChartingData() {
      if (isEmpty(this.preLoadedchartData)) {
        return this.chartData;
      }
      return this.preLoadedchartData;
    },
    updateLines(linesConfig) {
      this.chartConfig.lines = linesConfig;
    },
    getMetricTypes() {
      return get(this.chartData, 'dataset', []).map(dataObj => get(dataObj, 'metric', ''));
    },
    makeLineConfig(lineNames) {
      const addToConfg = (config, name, idx) => {
        set(config, name, {
          label: name,
          position: idx,
          type: 'line',
        });
        return config;
      };
      return lineNames.reduce(addToConfg, {});
    },
    deriveTitle() {
      if (this.getTitleFromResponse && this.combineResponseConfigTitles) {
        const titleFromResponse = this.getTitleFromResponse(this.chartData);
        return this.combineResponseConfigTitles(this.chartConfig.title, titleFromResponse);
      }
      return '';
    },
    getTitle() {
      return this.deriveTitle() || this.chartConfig.title;
    },
    deriveLines() {
      if (this.deriveLinesFromResponse) {
        const lineNames = this.getMetricTypes();
        const linesConfig = this.makeLineConfig(lineNames);
        this.updateLines(linesConfig);
      }
    },
    filterChartData() {
      if (!isEmpty(this.filterResponseFields)) {
        // eslint-disable-next-line max-len
        this.chartData.dataset = this.chartDataCopy.dataset.filter(dp => this.filterResponseFields.includes(dp.metric));
      }
    },
    onBucketFilterChange(chosenBucket) {
      this.$emit('onChartBreakdownChange', chosenBucket);
      this.internalBucket = chosenBucket;
      this.loadMetric();
    },
    onLineClickInChart(clickedDataPoint) {
      this.$emit('forwardedClickedDataPoint', clickedDataPoint);
    },
    onChartStatus(chartAction) {
      this.$emit('onChartStatus', chartAction);
    },
    loadMetric() {
      const [period_start, period_end] = this.dateRange;
      if (!(period_start && period_end && this.chosenBreakdown)) {
        return;
      }
      const params = {
        period_start,
        period_end,
        bucket: this.chosenBreakdown,
        filters: this.filters,
      };
      return this.$store
        .dispatch('metrics/getMetric', [this.chartConfig.metric, params])
        .then(data => {
          this.chartData = data;
          this.deriveLines();
          this.chartDataCopy = cloneDeep(data);
          this.filterChartData();
          this.$emit('onChartDataChange', data);

          if (this.shouldHandlePartialDates && this.shouldChangeXLabels) {
            let xLabels = {};
            this.chartData.dataset.forEach(item => {
              item.data_points.forEach((data_point) => {
                let dateMoment = moment(data_point.x);
                let value = data_point.x;
                let label = dateMoment.format('MMM DD');

                if (this.internalBucket?.value === 'week') {
                  value = dateMoment.day('Sunday').valueOf();
                  data_point.x = value;
                } else if (this.internalBucket?.value === 'month') {
                  label = dateMoment.format('MMM');
                }
                xLabels[value] = label;
              });
            });
            this.$emit('onXLabelsChange', xLabels);
          }

          return data;
        });
    },
    getDownloadData() {
      if (this.getOriginalData) {
        if (typeof this.getOriginalData === 'function') return this.getOriginalData();
        else return this.getOriginalData;

      } else {
        return this.chartData;
      }
    },
    exportChart() {
      const downloadData = cloneDeep(this.getDownloadData());
      const dataSet = get(downloadData, 'dataset', []);
      if (this.chartConfig.downloadUsingLineLabels
          && !some(dataSet.map(d => d.metric), isUndefined)) {
        downloadData.dataset = dataSet
          .map(metricData => ({...metricData,
            metric: get(this.chartConfig.lines,`${metricData.metric}.label`, metricData.metric)}));
      }
      // Filter the dataset if you want to show some columns in chart.
      const filteredDataset = {
        dataset: this.filteredExportColumns?.length ? downloadData.dataset.filter(item => this.filteredExportColumns.includes(item.metric)) : dataSet,
        relabeledHeaders: this.relabeledExportHeaders
      };

      downloadAsFile({
        filename: this.reportFileName,
        data: filteredDataset,
        dataType: this.chartConfig.exportType,
        downloadWithTime: this.downloadWithTime
      });
    },
    localizeChartLines(lines){
      return Object.fromEntries(
        Object.entries(lines).map(([key, value]) => [
          key,
          { ...value, label: this.$t(value.label) }
        ])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #324057;
  margin-bottom: 21px;
}

.help-icon {
  margin-left: 8px;
}
.select-box-container {
  width: 100px;
  height: 32px;
  float: left;
}
.export-chart {
  height: 30px;
  width: 40px;
  padding: 0;
  float: right;

  &:after {
    clear: both;
  }
}
.footnote{
  color: #8492A6;
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
