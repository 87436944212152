<template lang="pug">
  .content-inner.agents
    aiq-tabs(:model-value="$route.name" @tab-click="tabChange" type="border-card")
      aiq-tab-pane(v-for="tab in displayTabs"
                  :key="tab.name"
                  :label="tab.label"
                  :name="tab.name")
      router-view
</template>

<script>
import get from 'lodash/get';
import featureFlags from '@/store/modules/featureFlags';

const baseResource = '/settings/agents';
const buildResourcePath = (resource) => `${baseResource}/${resource}`;
const showAgentSubstatus = get(featureFlags, 'state.FEATURE_FLAGS.SETTINGS_AGENT_STATUS', true);

export default {
  computed: {
    displayTabs() {
      return this.tabs.filter(tab => (tab.public || this.canView(tab.resource)));
    },
  },
  created() {
    const isEditor = get(this.$route, 'meta.isEditor', false);
    const exist = this.tabs.find(tab => tab.name === this.$route.name);
    if (!isEditor && !exist) {
      this.tabChange({ index: 0 });
    }
  },
  data() {
    return {
      tabs: [
        // Not putting the Profile Tab for now
        {
          resource: buildResourcePath('manage'),
          label: 'Manage',
          name: 'Settings.Agents.Manage',
        },
        {
          resource: buildResourcePath('routing'),
          name: 'Settings.Agents.Routing',
          label: 'Routing',
        },
        {
          resource: buildResourcePath('access'),
          name: 'Settings.Agents.Access',
          label: 'Access',
        },
        {
          resource: buildResourcePath('dashboard'),
          name: 'Settings.Agents.Dashboard',
          label: 'Dashboard',
        },
        {
          resource: buildResourcePath('customer'),
          name: 'Settings.Agents.Customer',
          label: this.$t('settings_tab.admin_tab.tab_nav.customer_tab'),
        },
        {
          resource: buildResourcePath('notification'),
          name: 'Settings.Agents.Notification',
          label: 'Notification',
        },
        {
          resource: buildResourcePath('message_template'),
          name: 'Settings.Agents.Templates',
          label: this.$t('settings_tab.admin_tab.message_template.title'),
        },
        {
          public: true,
          resource: buildResourcePath('quick_responses'),
          name: 'Settings.Agents.QuickResponse',
          label: this.$t('settings_tab.admin_tab.quick_response_tab.title'),
        },
        ...(showAgentSubstatus ? [{
          resource: buildResourcePath('status'),
          name: 'Settings.Agents.Status',
          label: this.$t('settings_tab.admin_tab.status_tab.title'),
        }] : []),
      ],
    };
  },
  methods: {
    tabChange({ index }) {
      const currentTab = this.displayTabs[index];
      if (currentTab.name !== this.$route.name) {
        this.$router.push(currentTab);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-tab-pane .content-inner{
    padding: 0
  }
</style>
