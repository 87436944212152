import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';
import featureFlags from '@/store/modules/featureFlags';


// var m1 = moment;
// var m2 = new moment();
// m2.updateLocale(ack);

// debugger;

const hourFormat = 'h[h]';
const dayFormat = 'd[d]';
const minuteFormat = 'm[m]';
const secondFormat = 's[s]';

const buildDurationFormat = (duration) => {
  const formatParts = [];
  if (duration.days()) {
    formatParts.push(dayFormat);
  }
  if (duration.hours()) {
    formatParts.push(hourFormat);
  }
  if (duration.minutes()) {
    formatParts.push(minuteFormat);
  }
  if (duration.seconds()) {
    formatParts.push(secondFormat);
  }

  // default display
  if (!formatParts.length) {
    formatParts.push(secondFormat);
  }

  return formatParts.join(' ');
};

export const durationFormatter = (seconds = 0) => {
  const duration = moment.duration(seconds, 'seconds');
  const format = buildDurationFormat(duration);
  return moment.utc(duration.asMilliseconds()).format(format);
};

export const humanizeDuration = (seconds = 0) => moment.duration(seconds, 'seconds').humanize().replace(/a |an /, '');

export const getTimeZoneListWithAbbr = () => {
  const allTimeZones = moment.tz.names();
  const formattedTimeZones = allTimeZones.map(zone => `${zone} (${moment.tz(zone).zoneAbbr()})`);

  return formattedTimeZones;
};


/**
 * Get the abbreviation of a given time zone.
 * 
 * @param {string} zone - The time zone identifier (e.g., 'America/New_York').
 * @returns {string} - The abbreviation of the specified time zone.
 */
export const getTimeZoneAbbr = (zone) => {
  return moment.tz(zone).zoneAbbr();
};

/**
 * Determines the time zone to use based on feature flags and configuration.
 * 
 * @param {string} browserTimeZone - The browser's detected time zone.
 * @param {Object} dispatch - The Vuex dispatch instance.
 * @returns {string} - The time zone to use.
 */
export const useTimeZone = async (browserTimeZone, dispatch, configState) => {
  // Check if the feature flag for using settings timezone is enabled
  const useFeature = get(featureFlags, 'state.FEATURE_FLAGS.USE_SETTINGS_TIMEZONE', true);
  if (!useFeature) {
    return browserTimeZone;
  }

  //get data from state to avoid redundant api calls
  //this could server as a simple caching mechanism
  const timeZoneState = get(configState, 'timezone.input', '');

  if (!isEmpty(timeZoneState)) {
    return timeZoneState;
  }

  try {
    // Fetch the time zone configuration from the store
    const response = await dispatch('configs/getTimeZoneConfig');
    const timezone = get(response, 'input', '');

    // Return the configured timezone if available, otherwise fallback to browser timezone
    return isEmpty(timezone) ? browserTimeZone : timezone;
  } catch (error) {
    // Fallback to browser timezone in case of any error fetching from server
    return browserTimeZone;
  }
};

/**
 * Format a date with the timezone.
 * 
 * @param {Date} date - The date to format.
 * @param {Object} configState - The Vuex state for configuration.
 * @param {string} format - The moment.js format string.
 * @param {Object} dispatch - The Vuex dispatch instance.
 * @returns {string} - The formatted date with the timezone.
 */
export const formatDateWithTimezone = (date, configState, format, dispatch) => {
  // Check if the feature flag for using settings timezone is enabled
  const useFeature = get(featureFlags, 'state.FEATURE_FLAGS.USE_SETTINGS_TIMEZONE', true);
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!useFeature) {
    return moment.tz(date, browserTimeZone).format(format);
  }

  //get data from state to avoid redundant api calls
  const timeZoneState = get(configState, 'timezone.input', '');

  if (!isEmpty(timeZoneState)) {
    return moment.tz(date, timeZoneState).format(format);
  }

  try {
    // Fetch the time zone configuration from the store
    const response = dispatch('configs/getTimeZoneConfig');
    const timezone = get(response, 'input', '');

    // Return the configured timezone if available, otherwise fallback to browser timezone
    return isEmpty(timezone) ? moment.tz(date, browserTimeZone).format(format) : moment.tz(date, timezone).format(format);
  } catch (error) {
    // Fallback to browser timezone in case of any error fetching from server
    return moment.tz(date, browserTimeZone).format(format);
  }
};
