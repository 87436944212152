import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import mitt from 'mitt';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import * as log from 'loglevel';

import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import assign from 'lodash/assign';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { messages } from '@agentiq/aiq-js-libs';

import { AGENT_STATUS, RESOLVED_STATUS, DEFAULT_PICTURE_URL, MESSAGES_LIMIT, KEYBOARD_KEYS, FIXED_AGENT_STATUS } from '@/constants';
import {
  Events,
  scrollPosition,
  confirmBeforeDialogClose,
  setEndOfContentEditable,
} from '@/libs';
import ConversationLog from './children/ConversationLog/ConversationLog.vue';
import SidePanel from '../SidePanel/SidePanel.vue';
import { hosts } from '@/config/api.routes';
import { CUSTOM_EVTS, IDS } from '../../conversationConstants';
import {
  ScheduleForm,
  AssignAgentForm,
  EditCustomerForm,
  ImportanceForm,
  SuggestionsPopover,
  ChatInputContainer,
  VideoDialog,
  Cobrowse,
  TranslateButton,
  DocumentIntegrationDialog,
} from './children';

import Draggable from '@/libs/draggableHelper';

import SysMessageOn from '@/assets/svg/sys_msgs_on_b.vue';
import SysMessageOff from '@/assets/svg/sys_msgs_off_b.vue';
import { AGENT_SUGGESTION } from '@/constants/analytics';
import { isSegment, INTERNAL_SEGMENT } from '@/libs/customerSegments';
import ChannelIcon from '@/components/ChannelIcon.vue';
import AgentStatus from '@/components/AgentStatus.vue';
import AiTesting from '@/components/AiTesting/AiTesting.vue';
import VideoIcon from '@/assets/svg/video.vue';
import SendIcon from '@/assets/svg/send.vue';
import CobrowseIcon from '@/assets/svg/cobrowsing.vue';
import RelativeTimeIcon from '@/assets/svg/relative-time.vue';
import AbsoluteTimeIcon from '@/assets/svg/absolute-time.vue';

const { mapActions } = createNamespacedHelpers('conversations');

const AUTOSCROLL_ACTIVATION_THRESHOLD = 140;
const MAX_ITEM_LIMIT = 999999;

const WORKFLOW_HELPER_TAB = {
  EXAMPLE: 'example',
  SIMULATION: 'simulation',
};

const paused = get(FIXED_AGENT_STATUS, 'paused.value');
const onVideo = get(FIXED_AGENT_STATUS, 'paused.subStatus.onVideo');
const inCobrowse = get(FIXED_AGENT_STATUS, 'paused.subStatus.inCobrowse');
const onVideoAndInCobrowse = get(FIXED_AGENT_STATUS, 'paused.subStatus.onVideoAndInCobrowse');
const onVoice = get(FIXED_AGENT_STATUS, 'paused.subStatus.onVoice');
const onVoiceAndInCobrowse = get(FIXED_AGENT_STATUS, 'paused.subStatus.onVoiceAndInCobrowse');
const realtimeStates = [onVideoAndInCobrowse, onVideo, inCobrowse, onVoiceAndInCobrowse, onVoice];

const VOICE_CALL_STATE = {
  IDLE: 'idle',
  OUTGOING: 'outgoing',
  IN_CALL: 'in_call',
};

// Unable to add this client into Vue's data variable
// since it is not compatible with proxy object
let voiceClient = null;

@Component({
  name: 'chatPanel',
  provide() {
    return { 
      chatEventEmitter: this.chatEventEmitter,
      focusOnInputbox: this.focusOnInputbox,
    };
  },
  components: {
    ConversationLog,
    ScheduleForm,
    AssignAgentForm,
    EditCustomerForm,
    FontAwesomeIcon,
    ImportanceForm,
    ScaleLoader,
    SuggestionsPopover,
    SidePanel,
    ChatInputContainer,
    SysMessageOff,
    SysMessageOn,
    ChannelIcon,
    VideoDialog,
    VideoIcon,
    CobrowseIcon,
    SendIcon,
    Cobrowse,
    TranslateButton,
    AgentStatus,
    RelativeTimeIcon,
    AbsoluteTimeIcon,
    AiTesting,
    DocumentIntegrationDialog,
  },
  watch: {
    conversation: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue.id !== (oldValue && oldValue.id)) {
          this.stopTimer();
          this.startTimer();
          this.loaded = false;
          this.selectedSuggestionId = 0;
          this.changeTypingStatus(false);
          this.inAutoScrollToBottom = true;
          this.prevScrollHeight = 0;
          this.loadMessagesBaseOnScroll();
        }
      },
    },
    newMessage(newValue) {
      if (newValue) this.showPlusButton = true;
      else {
        this.showPlusButton = false;
        this.originalMessage = null;
        this.messageTranslation = null;
      }
    },
    isSuggestionsOpened(newValue, oldValue) {
      if (newValue && (newValue !== oldValue)) {
        this.sendSuggestionShow(this.suggestions, newValue);
      }
    },
    suggestions(newValue, oldValue) {
      if (newValue instanceof Array && !isEqual(newValue, oldValue)) {
        this.sendSuggestionShow(newValue, this.isSuggestionsOpened);
      }
    },
    systemMessagesDisplay() {
      this.$nextTick(() => {
        this.scrollToBottom(true);
      });
    },
  },
  computed: {
    videoIdentity() {
      return `agent-${this.agent.id}`;
    },

    agentVoiceIdentity() {
      return this.videoIdentity;
    },

    customerVoiceIdentity() {
      return `customer-${this.customer.id}`;
    },

    imagesOrDocsUploadEnabled() {
      return !this.imageOrPdfUploading;
    },
    isPrimaryAgent() {
      return get(this.customer, 'primary_agent', null) === this.me;
    },
    leaveConversationMessage() {
      if (this.isPrimaryAgent) {
        return 'Primary agents cannot leave the conversation';
      }
      return 'Leave Conversation';
    },
    ...mapGetters({
      isOnVacation: 'agent/isOnVacation',
      agentsList: 'agents/sortedList',
      conversations: 'conversations/conversationsMessages',
      isAgentUserInConversation: 'conversations/isAgentUserInConversation',
      isRealtimeContext: 'conversations/isRealtimeContext',
    }),
    ...mapState({
      chatMessages: state => state.conversations.messages,
      permissions: state => state.agent.permissions,
      attachedFiles: state => state.files.attachedFiles,
      availability: state => get(state, 'agent.profile.available', AGENT_STATUS.Away.field),
      customer: state => state.conversations.selectedCustomer,
      loading: state => state.conversations.customerConversationsLoading,
      me: state => get(state, 'agent.profile.id') || null,
      messages: state => state.messages.messages,
      conversation: state => state.conversations.selected,
      totalMessagesOfCustomer: state => state.conversations.totalMessagesOfCustomer,
      typing: state => state.messages.typing,
      systemMessagesDisplay: state => state.conversations.systemMessagesDisplay,
      agent: state => state.agent.profile,
      isOpen: state => state.conversations.isConversationOpen,
      isMobileContext: state => state.settings.isMobileContext,
      attachmentsHistoryMode: state => state.conversations.attachmentsHistory.mode,
      languages: state => state.translation.languages,
      supportedLanguageTranslation: state => state.configs.config.supported_language_translation,
      chatPanelConfig: state => state.settings.chatPanelConfig,
      isSuggestionsOpened: state => state.conversations.isSuggestionsOpened,
      isTimestampAbsolute: state => state.conversations.isTimestampAbsolute,
      isWorkflowSimulationDone: state => state.aiEngine.conversationEnded,
      featureFlags: state => state.featureFlags.FEATURE_FLAGS,
    }),
    isSendDisabled() {
      if (!this.sendMessagePermission) {
        return true;
      }
      return !this.newMessage && !this.attachedFiles.length;
    },
    sendMessagePermission() {
      return this.canView('/conversations/inputbox/message');
    },
    translateButtonLanguages() {
      const customerLanguageCode = get(this.customer, 'profile.language_code');
      const exist = this.supportedLanguageTranslation
        .some(l => l.code === customerLanguageCode);
      if (exist || !customerLanguageCode) return this.supportedLanguageTranslation;
      const customerLanguage = this.languages.find(l => l.code === customerLanguageCode) || {};
      return [customerLanguage, ...this.supportedLanguageTranslation];
    },
    shouldShowCobrowse() {
      // TODO: we should check if customer is able to cobrowse and use right channel
      return this.showCobrowse;
    },
    /* eslint-disable */
    agentInConversation () {
      return this.agents.find(a => a.id === this.me);
    },
    conversationChannel () {
      return get(this.conversation, "channel", "");
    },
    // A key to save text locally from input box
    messageInputCacheKey () {
      return `msg-cache-${this.conversation.customer_id}`;
    },
    customerName () {
        // TODO: put this function in a comman place and reuse it. it is getting used in conversation component
        const { channel } = this.conversation;
        const { customer } = this;
        const firstName = get(customer, 'profile.first_name');
        if (firstName) {
          const lastName = get(customer, 'profile.last_name');

          return lastName ? firstName + " " + lastName : firstName;
        } else {
          if ((channel === 'twitter' || channel === 'twitter-dm') && customer.profile.displayName) {
            return customer.profile.displayName;
          } else {
            const id = get(customer, 'id', 0);
            return this.$t(
              'conversation_tab.left_panel.conversation_item.default_title',
              { customer_id: id },
            );
          }
        }
    },
    headers () {
      return {
        Authorization: this.$store.getters["agent/authToken"]
      };
    },
    isCustomerAnonymous() {
      return isSegment(this.customer, INTERNAL_SEGMENT.ANONYMOUS);
    },
    isConversationClosedAndResolved() {
      return get(this.conversation, 'status', 'active') === 'closed' &&
        ![ RESOLVED_STATUS.UNRESOLVED_BY_AGENT,
          RESOLVED_STATUS.UNRESOLVED_AI].includes(get(this.conversation,
            'resolved_status',
            RESOLVED_STATUS.UNKNOWN));
    },
    // TODO: The name of function needs to be changed to files or attachedFiles.
    files() {
      const files = this.$store.getters["files/attachedFiles"]
        .map(file => {
          return {
              message_type: "file",
              file
          };
        });

      if (!isEmpty(files)) {
        return files;
      }

      return;
    },
    isLocked () {
      return this.conversation.locked_by &&
                get(this.conversation, "locked_by.id", -1) === this.me;
    },
    isInProgress () {
      return !!(this.conversation.locked_by &&
        get(this.conversation, "locked_by.id", -1) !== this.me);
    },
    lockedBy () {
      return get(this.conversation, "locked_by.fullName", "");
    },
    agents () {
      let agents = get(this.conversation, "agents", []);
      return agents.map(selectedAgent => {
        let attachedToConversationAgent = this.agentsList.find(agent => selectedAgent.id === agent.id);

        if (attachedToConversationAgent) {
          selectedAgent = cloneDeep(attachedToConversationAgent);
        }

        let { id, email = "", fullName = "", picture, available } = selectedAgent;

        return { id, email, fullName, picture, available };
      });
    },
    suggestions () {
      return this.conversation.suggestions || [];
    },
    maxSymbols () {
      const {customer, channel} = this.conversation;
      if (channel === "twitter") {
          return 280 - (customer.profile.preferredUsername ? (customer.profile.preferredUsername.length + 2) : 0);
      }
      return 280;
    },
  },
  inject: ['shortcutEmitter'],
  methods: {
    ...mapActions([
      'removeAgent',
      'selectConversation',
      'updateConversation',
    ]),
    focusOnInputbox() {
      const inputBox = this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`);
      // Element is automatically focused from setting the cursor here
      setEndOfContentEditable(inputBox);
    },
  },
})
class ChatPanel extends Vue {
  // For messages pagination
  limit = MESSAGES_LIMIT;
  loaded = false;

  chatEventEmitter = mitt();
  clipColor = '#5a88de';
  openedDialogRef = '';
  selectedSuggestionId = 0;
  typingTimeout = null;
  imageOrPdfUploading = false;
  sendMessageInProgress = false;
  isOnTyping = false;
  conversationTimer = null;
  isSuggestionEdited = false;

  prevScrollPositionFromBottom = 0;
  prevScrollHeight = 0;
  inAutoScrollToBottom = true;

  showSidePanel = true;

  showVideoSetting = false;
  showVideo = false;
  videoConfig = null;
  videoToken = null;
  videoSize = 'small';

  newMessage = null;
  originalMessage = null;
  messageTranslation = null;
  showPlusButton = false;

  showCobrowse = false;
  loadingCobrowse = false;
  draggable = null;

  lastCallMessage = null;

  showDocumentSignDialog = false;

  voiceCallState = VOICE_CALL_STATE.IDLE;
  showVoice = false;

  /**
   * Event listeners
   */
  listeners = {};
  shortcutEvts = [
    [CUSTOM_EVTS.LEAVE, this.handleLeaveConversation],
    [CUSTOM_EVTS.STAR, this.starConversation],
  ];

  workflowSimulationActiveTab = WORKFLOW_HELPER_TAB.EXAMPLE;
  workflowSimulation = {
    show: false,
    tab: WORKFLOW_HELPER_TAB.EXAMPLE,
    item: null,
  };

  created() {
    this.$_IDS = IDS;
    this.$_url = `${hosts.management}files/upload`;
  }

  agentAvatarThumbnailUrl(agent) {
    this.$_DEFAULT_PICTURE_URL = DEFAULT_PICTURE_URL;
    return get(agent, 'picture.payload.thumbnail.url', this.$_DEFAULT_PICTURE_URL);
  }
  agentStatus(agent) {
    return get(agent, 'available');
  }

  mounted () {
    this.chatEventEmitter.on('typing', this.onTyping.bind(this));
    this.chatEventEmitter.on('simulate_workflow', this.onLaunchWorkflowHelper.bind(this));
    this.shortcutEvts.forEach(evt => {
      this.shortcutEmitter.on(...evt);
    });

    this.listeners = {
      "new.message": () => {
        this.handleScrollToBottom();
      },
    };

    map(this.listeners, (listener, key) => {
      Events.on(key, listener);
    });

    this.handleScrollToBottom(true);

    if (this.canView('/conversations/message/translation')) {
      this.$store.dispatch('translation/languages');
    }

    // For /run_workflow and /run_dialog
    this.$store.dispatch("workflows/getWorkflowsList", [{limit: MAX_ITEM_LIMIT}]);
    this.$store.dispatch("dialogs/getDialogsList", [{limit: MAX_ITEM_LIMIT}]);

    this.startTimer();
  }

  beforeUnmount() {
    this.stopTimer();
    this.stopTyping();
    this.chatEventEmitter.off('typing', this.onTyping.bind(this));
    this.shortcutEvts.forEach(evt => {
      this.shortcutEmitter.off(...evt);
    });
  }

  onBeforeUpload () {
    this.imageOrPdfUploading = true;
  }

  unmounted() {
    map(this.listeners, (listener, key) => {
      Events.off(key, listener);
    });
    this.draggable && this.draggable.listeners(false);
  }

  changeTypingStatus(value) {
    const name = value ? 'start-typing' : 'stop-typing';
    this.$store.dispatch('notifications/emitEventToSocket', { name, payload: this.customer.room});
  }

  onTyping (message) {
    this.typingTimeout && clearTimeout(this.typingTimeout);
    !this.isOnTyping && this.changeTypingStatus(true);
    this.isOnTyping = true;
    this.typingTimeout = setTimeout(this.stopTyping, 2000);
    if (this.selectedSuggestionId !== 0 && !this.isSuggestionEdited) {
      this.isSuggestionEdited = true;

      this.$store.dispatch('notifications/emitEventToSocket', {
        name: AGENT_SUGGESTION.EDIT,
        payload: {
          conversation: message,
          suggestionSelected: this.suggestions.find(e => e.id === this.selectedSuggestionId).value,
          createdAt: new Date(),
          conversation_id: this.conversation.id
        },
      });
    }
  }

  stopTyping () {
    this.isOnTyping = false;
    this.changeTypingStatus(false);
  }

  toggleIsSuggestionsOpened () {
    this.$store.commit('conversations/TOGGLE_IS_SUGGESTIONS_OPENED');
    this.handleScrollToBottom();
  }

  setIsSuggestionsOpened (value) {
    this.$store.commit('conversations/SET_IS_SUGGESTIONS_OPENED', value);
    this.handleScrollToBottom();
  }

  selectSuggestion (suggest) {
    this.selectedSuggestionId = suggest.id;
    this.chatEventEmitter.emit('setText', suggest.value);
    this.$store.dispatch('notifications/emitEventToSocket', {
      name: AGENT_SUGGESTION.CLICK,
      payload: {
        id: suggest.id,
        text: suggest.value,
        createdAt: new Date(),
        conversation_id: this.conversation.id
      },
    });
  }

  sendSuggestionShow (suggestions, isOpen) {
    if (isOpen && suggestions.length > 0) {
      this.$store.dispatch('notifications/emitEventToSocket', {
        name: AGENT_SUGGESTION.SHOW,
        payload: {
          suggestions: this.suggestions,
          createdAt: new Date(),
          conversation_id: this.conversation.id
        },
      });
    }
  }

  loadMessagesBaseOnScroll() {
    this.loaded = this.totalMessagesOfCustomer <= this.chatMessages.length;
    this.$nextTick(() => {
      const scrollableContainer = this.$el.querySelector('.scroll');
      /*
        Get more messages when all the messages hasn't been loaded, system messages are not being displayedand we don't have scroll.
        Need to do this cause we are going to get blocked in pagination when system messages are the only results and they aren't showing up.
      */
      const hasScroll = scrollableContainer.scrollHeight > scrollableContainer.clientHeight;
      if(!this.loaded && !this.loading && !this.systemMessagesDisplay && !hasScroll){
        this.loadMessages();
      }
       // Allow the scroll maintain its position when the container increase its height or redirec to the bottom
      if (this.prevScrollHeight) {
        scrollableContainer.scrollTop = scrollableContainer.scrollHeight - this.prevScrollHeight;
      } else {
        this.scrollToBottom(true);
      }
    });
  }

  loadMessages () {
    return this.$store.dispatch("conversations/getCustomerCoversationsFromMessages", {
        params: {
          limit: MESSAGES_LIMIT,
          offset: this.chatMessages.length,
        },
        customer: this.customer,
      }).then(data => {
        this.loadMessagesBaseOnScroll();
        return data;
      });
  }

  onScroll ({ y }) {
    const scrollContainer = this.$el.querySelector('.scroll')
    if (!scrollContainer) {
      return;
    }

    const currScrollPositionFromBottom = scrollPosition(scrollContainer);
    if (currScrollPositionFromBottom > AUTOSCROLL_ACTIVATION_THRESHOLD
      && this.prevScrollPositionFromBottom < currScrollPositionFromBottom) {
      this.inAutoScrollToBottom = false;
    } else {
      this.inAutoScrollToBottom = true;
    }

    this.prevScrollPositionFromBottom = currScrollPositionFromBottom;
    this.prevScrollHeight = scrollContainer.scrollHeight - y.value;

    if (!this.loaded && !this.loading && y.value <= 10) {
      this.loadMessages();
    }
  }

  async sendIconClick(){
    // Check if the current message is a command.
    if (this.newMessage && this.newMessage.startsWith(KEYBOARD_KEYS.BACKSLASH)) {
      const enterEvent = { key: KEYBOARD_KEYS.ENTER, target: null };
      this.$refs.inputContainer.keyListener(enterEvent);
      return;
    }

    await this.onMessageEntered(this.newMessage);
  }
  async onMessageEntered(message) {
    await this.sendMessage(message);
    this.handleScrollToBottom(true)
  }

  toggleSystemMessagesDisplay() {
    this.$store.dispatch('conversations/setSystemMessagesDisplay', !this.systemMessagesDisplay);
  }

  toggleIsTimestampAbsolute() {
    this.$store.dispatch('conversations/toggleIsTimestampAbsolute');
  }

  handleAttachedItems() {
    const { dispatch } = this.$store;
    let hasAttachedItems = false;

    if (this.attachedFiles.length) {
      dispatch("files/clearAttachedFiles");
      hasAttachedItems = true;
    }

    if(hasAttachedItems) {
      dispatch('conversations/getAttachments', {
        customerId: this.customer.id,
        mode: this.attachmentsHistoryMode
      });
    }
  }

  onUploadSuccess (file) {
    this.$store.dispatch("files/attachFile", {file, source: 'chatUpload'});
    this.imageOrPdfUploading = false;
  }

  onUploadFailure () {
    this.$aiq.notify.error("Unable to upload the image. Please try again.")
    this.imageOrPdfUploading = false;
  }

  handleCobrowseMessage(state, msg) {
    this.handleFeatureMessage('cobrowsing', state, msg);
  }

  handleFeatureMessage(feature, state, msg) {
    return this.sendSystemMessage([feature, state].join('.'), msg);
  }

  sendSystemMessage(action, content) {
    return this.$store.dispatch("messages/sendSystemMessage", {
      conversation: pick(this.conversation, ['id']),
      action,
      content,
    });
  }

  statusChangeCheck() {
    return new Promise((resolve) => {
      if (this.agent.available === 'away' && this.isOnVacation) {
        return this.confirmDialog(
          'Currently Away',
          'Sending a message changes your Availability to Busy.')
          .then(confirm => {
            /* 
              We are not proceeding to manually change the status since backend code 
              detect wether the current agent status is away to set it up to busy
            */
            resolve(confirm)
          }, 
         ()=> resolve(false));
      }
      resolve(true)
    })
  }

  async sendMessage (message) {
    if (message.match(/^\s/) !== null) {
      return this.$aiq.notify.error("Unable to send. A message cannot begin with a space.");
    }
    this.stopTyping();
    if(!await this.statusChangeCheck()) return;
    let selectedSuggestionId = this.selectedSuggestionId;
    if (this.sendMessageInProgress || (!message && !this.files)) {
      return Promise.resolve();
    } else {
      this.sendMessageInProgress = true;
      const payload = {id: this.conversation.id, content: message, files: this.files };
      if (this.originalMessage) {
        payload.originalContent = this.originalMessage;
        payload.translation = this.messageTranslation;
      }
      return this.$store.dispatch("conversations/sendMessage", payload )
        .then(data => {
          if (this.conversation.id === this.conversation.id) {
            this.$store.dispatch("conversations/readConversation", this.conversation.id);
          }

          // reload conversations if conversation is rotated
          if (this.conversation.id !== data.conversation.id) {
            this.$store.dispatch('conversations/selectConversation', {
              id: data.conversation.id,
              customer: this.customer,
            });
          }

          selectedSuggestionId > 0 && this.sendMessageToAnalitics(message, selectedSuggestionId);
          this.isSuggestionsOpened && this.toggleIsSuggestionsOpened();

          this.handleAttachedItems();

          (this.isSuggestionsOpened && selectedSuggestionId !== 0) && this.setIsSuggestionsOpened(false);
          this.selectedSuggestionId = 0;
          this.isSuggestionEdited = false;
          this.chatEventEmitter.emit('clearInput');
          this.sendMessageInProgress = false;
        })
        .catch(err => {
          this.sendMessageInProgress = false;
          return this.$aiq.notify.error("Unable to send message.")
        });

      }
  }

  sendMessageToAnalitics (content, id) {
    let suggestion = this.suggestions.find(e => e.id === id).value;

    this.$store.dispatch('notifications/emitEventToSocket', {
      name: AGENT_SUGGESTION.SEND,
      payload: {
        messageSent: { content },
        suggestion,
        conversation_id: this.conversation.id,
        edited: suggestion !== content,
        createdAt: new Date()
      },
    });
  }

  starConversation (value = !this.conversation.isStarred) {
    return this.$store.dispatch("conversations/starConversation",
                        [this.conversation.id, value]);
  }

  unlock () {
    return this.$store.dispatch('conversations/unlockConversation', this.conversation.id)
      .then(() => {
        this.$aiq.notify.info("Conversation was unlocked successfully.");
      });
  }

  // TODO (Gabe) - call and implement this
  scheduleMessage ({ message, date }) {
    this.$store.dispatch("conversations/scheduleMessage", [
      this.conversation.id,
      message,
      date
    ]);
  }

  saveCustomer (customer) {
    let newCustomer = this.conversation.customer;
    newCustomer.profile = customer;
    this.$store.dispatch("conversations/updateCustomer", [
      newCustomer.id,
      newCustomer]).then(data => {
        this.closeModal("editCustomerForm");
        this.$aiq.notify.info("Customer details were updated.");
      });
  }

  scrollToBottom (always) {
    if (this.inAutoScrollToBottom || always) {
      const lastMessage = this.$el.querySelector(".scroll")?.lastElementChild?.querySelector(".conversation__info");
      if (!lastMessage) {
        return false;
      };

      const images = document.querySelectorAll(".message-list .message-bubble img");
      if (!images.length && lastMessage.scrollIntoView) {
        lastMessage.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        return;
      };

      Promise.all(
        Array.from(images)
            .filter(img => !img.complete)
            .map(img => new Promise(resolve => {
              img.onload = img.onerror = resolve;
            }))
      ).then(() => {
        lastMessage.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
      });
    };
  }

  handleScrollToBottom (always) {
    this.$nextTick(()=> this.scrollToBottom(always));
  }

  exportConversation () {
    this.confirmDialog(
      "Export Conversation?",
      "Do you really want to export this conversation?")
      .then(confirm => {
      if (confirm) {
        this.$store.dispatch("conversations/exportConversation", this.conversation.id)
      }
    }, cancel => {
    });
  }

  openModal (ref) {
    this.openedDialogRef = ref;
  }

  closeChatPanel(){
    this.$store.dispatch('conversations/updateIsConversationOpen', false);
  }

  closeModal (e) {
    this.openedDialogRef = "";
  }

  handleAssignAgentModalClose () {
    if (!this.$refs.assignAgentForm.getIsChanged()) {
      this.closeModal();
      return;
    }

    confirmBeforeDialogClose(this, this.closeModal);

  }

  confirmDialog (title, text = "", config) {
    return this.$aiq.confirm(title, text, config);
  }

  async handleLeaveConversation() {
    if (!this.isPrimaryAgent) {
      // TODO(slaven) - move all prompt texts to backend configs
      return this.confirmDialog(
        'Before you leave...',
        'Once you leave you cannot access this conversation any more.  Are you sure you want to leave?',
        { confirmButtonText: 'Leave' },
        )
        .then(confirm => {
          if (confirm) {
            return this.leaveConversation();
          }
        }, cancel => {});
    }
  }

  leaveConversation(removePrimary) {
    const params = {
      conversation: this.conversation,
      agentId: this.me,
      removePrimary
    };

    return this.removeAgent(params)
      .then(data => {
        this.$aiq.notify.info("You have left the conversation.");
      });
  }

  handleSaveAgents (agents) {
    this.updateAgents(agents.map(e => e.id)).then(() => {
      this.$aiq.notify.info("Agent assignment list was updated successfully.");
      this.closeModal("assignAgentModalForm");
    });
  }

  updateAgents (agents) {
    return this.$store.dispatch("conversations/updateAgents", [this.conversation.id, agents]);
  }

  selectResponse (value) {
    this.chatEventEmitter.emit('setText', value);
  }

  startTimer () {
    this.conversationTimer = this.formatTimer(new Date(this.conversation.created_at));
    if (this.conversation.closed_at) {
      return;
    };

    this.conversationTimerInterval = window.setInterval(() => {
      this.conversationTimer = this.formatTimer(new Date(this.conversation.created_at));;
    }, 1000);
  }

  stopTimer() {
    if (!this.conversationTimerInterval) {
      return;
    }

    window.clearInterval(this.conversationTimerInterval);
  }

  formatTimer(date) {
    const duration = moment.duration(Date.now() - date);
    const oneDay = moment.duration(1, "day");
    if (duration >= oneDay) {
      const days = duration.days();
      const hoursAndMinutes = moment.utc(duration.asMilliseconds()).format("H[h] m[m]");
      return `${days}d ${hoursAndMinutes}`;
    } else {
      return moment.utc(duration.asMilliseconds()).format("H[h] m[m]");
    }
  }

  async markAsCompleted () {
    try {
      const isConvClosed = get(this.conversation, 'status', 'active') === 'closed' ;
      // If a conversation is closed, we are updating resolved_status.
      const resolved_status = RESOLVED_STATUS.RESOLVED_BY_AGENT;
      await this.$store.dispatch(
        'conversations/closeConversation',
        { id: this.conversation.id, resolved_status });
      this.$aiq.notify.success('Conversation Closed');
    } catch (_) {
      this.$aiq.notify.error('Unable to mark conversation.')
    }
  }

  onVideo () {
    this.showVideoSetting = true;
  }

  async onVideoSettingComplete (videoConfig, shouldSendMsg = true) {
    this.showVideoSetting = false;

    this.videoToken = await this.$store.dispatch('agent/getVideoToken', { identity: this.videoIdentity, room: videoConfig.room.name });
    this.videoConfig = videoConfig;

    if (shouldSendMsg) {
      const payload = assign({ id: this.conversation.id }, messages.makeCallMessage(
        'Join a Video Call',
        this.videoConfig.room.name,
        'video'
      ));

      await this.$store.dispatch("conversations/sendMessage", payload);
    }
    this.showVideo = true;
    this.$store.dispatch('conversations/updateIsVideoContext', true);
    this.handleFeatureMessage('video-call', 'agent.join', `Video call session joined by agent.`);
    this.doDraggable('.video-container');
    await this.updateSubstatus();
  }

  doDraggable(targetClass){
    setTimeout(() => {
      this.draggable = Draggable('.chat-panel_content', targetClass);
    }, 300);
  };

  async onVideoEnded () {
    this.showVideo = false;
    this.$store.dispatch('conversations/updateIsVideoContext', false);
    this.draggable.listeners(false);
    await this.updateSubstatus();
  }

  onVoiceCallEvent({ name, value }) {
    if (name === 'hang-up') {
      voiceClient.hangup();
    } else if (name === 'mute') {
      voiceClient.mute(value);
    }
    this.handleFeatureMessage('voice-call', `agent.${name}`, `Voice call ${name} button is clicked by agent.`);
  }

  async getPreviousAgentStatus() {
    try {
      const statusHistory = await this.$store.dispatch('agent/getAgentStatusHistory');
      const currentStatus = statusHistory[0] || {};
      const previousStatus = statusHistory.find(s => !realtimeStates.includes(s.status));
      return previousStatus;
    } catch(err) {
      log.warn(err.message);
    }
    return { value: AGENT_STATUS.Available.field, status: ''}; // move to available if there is an error.
  }

  onVideoSizeChangeClick () {
    this.draggable.setTranslate(10, 10);
    this.videoSize = this.videoSize === 'small' ? 'big' : 'small';
  }

  onLaunchVideoSetting () {
    if (this.showVideoSetting || this.showVideo) {
      return;
    }

    this.showVideoSetting = true;
  }

  onCall(msg) {
    const roomName = get(msg, 'payload.call.namespace');
    this.onVideoSettingComplete({ room: { name: roomName } }, false);
  }

  async setShowCobrowse(val) {
    if (val) {
      await this.createCobrowseMessage();
    }
    this.showCobrowse = val;
    this.$store.dispatch('conversations/updateIsCobrowseContext', val);
    await this.updateSubstatus();
  }

  async setShowDocumentSignDialog(val) {
    this.showDocumentSignDialog = true;
  }

  async setVoiceConfigDialog() {
    // A temporary UI for initial pass
    return this.$aiq.confirm(
      this.$t('conversation_tab.chat_panel.voice_call_dialog.title'),
      this.$t('conversation_tab.chat_panel.voice_call_dialog.description'),
      {
        confirmButtonText: this.$t('conversation_tab.chat_panel.voice_call_dialog.yes_btn'),
        cancelButtonText: this.$t('conversation_tab.chat_panel.voice_call_dialog.no_btn') })
      .then(async confirm => {
        try {
          const token = await this.$store.dispatch('agent/getVoiceToken', { identity: this.agentVoiceIdentity });
          voiceClient = new this.$aiq.VoiceClient(token);
          voiceClient.on('disconnected', async () => {
            this.voiceCallState = VOICE_CALL_STATE.IDLE;
            voiceClient = null;
            this.$store.dispatch('conversations/updateVoiceCallContext', false);
            this.handleFeatureMessage('voice-call', 'agent.ended', 'Voice call ended');
            this.showVoice = false;
            await this.updateSubstatus();
          });

          voiceClient.on('accepted', () => {
            this.voiceCallState = VOICE_CALL_STATE.IN_CALL;
          });
          voiceClient.callTo(this.customerVoiceIdentity);
          this.voiceCallState = VOICE_CALL_STATE.OUTGOING;
          this.doDraggable('.voice-container');
          this.$store.dispatch('conversations/updateVoiceCallContext', true);
          this.handleFeatureMessage('voice-call', 'agent.initiated', 'Voice call request initiated');
          this.showVoice = true;
          await this.updateSubstatus();
        } catch (err) {
          this.$aiq.notify.error(`Unable to make a call: ${err.message}`);
        }
      }, _cancel => {/* Do nothing */});
  }

  async createCobrowseMessage() {
    this.loadingCobrowse = true;
    const payload = assign({ id: this.conversation.id }, messages.makeCallMessage(
      'Join a Cobrowse Session',
      '',
      'cobrowse'
    ));

    const message = await this.$store.dispatch("conversations/sendMessage", payload);
    this.lastCallMessage = message;
    this.loadingCobrowse = false;
  }

  async onCobrowseSessionClosed(session) {
    this.setShowCobrowse(false);
    if (this.lastCallMessage) {
      let { conversation_id, id, payload, metadata = {} } = this.lastCallMessage;
      const ended = get(session, 'ended', moment());
      payload = merge(payload, {
        call: {
          namespace: get(session, 'id'),
          duration: moment(ended).diff(metadata.accepted_at || ended, 'seconds').toString(),
          ended_at: moment(ended),
        }
      });

      await this.$store.dispatch("conversations/updateMessage", {
        conversationId: conversation_id,
        messageId: id,
        payload
      });
      this.lastCallMessage = null;
    }
    await this.updateSubstatus();
  }

  onCobrowseSessionAccepted() {
    if (this.lastCallMessage) {
      this.lastCallMessage = merge(this.lastCallMessage, {
        metadata: {
          accepted_at: moment(),
        }
      });
    }
  }

  async translate(targetLanguage) {
    if (!this.newMessage) return;
    try {
      const { translation, language } = await this.$store.dispatch('translation/translate', { q: this.newMessage, target: targetLanguage.code });
      /**
       * Calling setText function in that way because inside the function are trigger some events needed by
       * the ChatInput component to work as expected. Also inside ChatInput component with don't have a
       * v-model binding and the set and get of the chat input is done with manually functions and operatio
       * like document.getElementById and so on.
       */
      this.originalMessage = this.newMessage;
      this.messageTranslation = language && targetLanguage ? { from: language.code, to: targetLanguage.code } : null;
      this.$refs.inputContainer.$refs.chatInput.setText(translation);
    } catch (err) {
      this.$aiq.notify.error('Unable to translate content');
    }
  }

  onVideoEvent({ name, identity }) {
    // 'disconnected' gets fired when a video call connection is
    // either hang-up by clicking button or not reconnected after a certain
    // seconds
    if (name === 'disconnected' && this.videoIdentity !== identity && this.showVideo) {
      // Should we also check if cobrowsing is ongoing?
      return this.$aiq.confirm(
        'Customer Hang up',
        'Customer has left video call. Do you want to wait or leave?',
        { confirmButtonText: 'Leave', cancelButtonText: 'Wait' })
        .then(confirm => {
          this.$refs['video-panel'].onLeaveVideoCall();
        }, _cancel => {/* Do nothing */});
    }
  }

  onVideoUIEvent({ event, name }) {
    if (event === 'click') {
      this.handleFeatureMessage('video-call', `agent.${name}`, `Video call ${name} button is clicked by agent.`);
    }
  }

  /**
   * Return Icon's visibility by checking ACL & its configuration
   */
  shouldDisplayInputboxIcon(name) {
    const disabledSegments = get(this.chatPanelConfig, `invisibility_control_by_segment.inputbox.${name}`, []);
    const shouldDisabled = disabledSegments.reduce((acc, seg) => (isSegment(this.customer, seg) || acc), false);
    return this.canView(`/conversations/inputbox/${name}`) && !shouldDisabled;
  }

  /**
   * Return Icon's visibility by checking ACL & its configuration
   */
  shouldDisplayVoiceCallIcon() {
    // Check feature flag
    return this.featureFlags.VOICE_CALL && this.shouldDisplayInputboxIcon('voice_call');
  }

  async onWorkflowSimulate(item) {
    // name is could be a display name
    const { id, name, workflow_name } = item;

    if (!id && !workflow_name) {
      throw new Error(`Unable to simulate a workflow with ${id} ${name}`);
    }

    try {
      this.$store.dispatch('aiEngine/reset'); // clean up cache
      this.$store.dispatch('aiEngine/sendWorkflow', workflow_name);
      this.workflowSimulation = { show: true, tab: WORKFLOW_HELPER_TAB.SIMULATION, item };
      this.$aiq.notify.info(`${name} workflow started`);
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  async onLaunchWorkflowHelper(item) {
    this.workflowSimulationActiveTab = WORKFLOW_HELPER_TAB.EXAMPLE;
    this.onWorkflowExample(item);
  }

  async onWorkflowExample(item) {
    // name is could be a display name
    const { id, name, workflow_name } = item;

    if (!id && !workflow_name) {
      throw new Error(`Unable to launch a workflow example ${id}}`);
    }

    try {
      this.$store.dispatch('aiEngine/reset'); // clean up cache
      this.$store.dispatch('aiEngine/setWorkflowExample', item);
      this.workflowSimulation = { show: true, tab: WORKFLOW_HELPER_TAB.EXAMPLE, item };
      this.$aiq.notify.info(`${name} workflow started`);
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }

  onWorkflowSimulationTabClick(val) {
    if (val === this.workflowSimulation.tab) {
      return;
    }
    if (val === WORKFLOW_HELPER_TAB.EXAMPLE) {
      this.onWorkflowExample(this.workflowSimulation.item);
    } else {
      this.onWorkflowSimulate(this.workflowSimulation.item);
    }
    this.workflowSimulationActiveTab = val;
  }

  onCloseWorkflowSimulation() {
    this.workflowSimulationActiveTab = WORKFLOW_HELPER_TAB.EXAMPLE;
    this.workflowSimulation = { show: false, tab: WORKFLOW_HELPER_TAB.EXAMPLE, item: null };
  }

  async onDocumentSignMessageReceived({ title, integration, payload }) {
    try {
      const reqPayload = assign(
        { id: this.conversation.id },
        messages.makeSignatureMessage('Sign Here', title, integration, payload));
      await this.$store.dispatch("conversations/sendMessage", reqPayload);
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
    this.showDocumentSignDialog = false;
  }

  // A single place to handle update or restore substatus
  async updateSubstatus() {
    if (!this.featureFlags.SETTINGS_AGENT_STATUS) {
      return {};
    }

    let newStatus = {
      value: paused,
      status: '',
    };

    // Handle if substatus is enabled
    if (this.showCobrowse) {
      newStatus.status = inCobrowse;
      if (this.showVideo) {
        newStatus.status = onVideoAndInCobrowse;
      } else if (this.showVoice) {
        newStatus.status = onVoiceAndInCobrowse;
      }
    } else if (this.showVideo) {
      newStatus.status = onVideo;
      if (this.showCobrowse) {
        newStatus.status = onVideoAndInCobrowse;
      }
    } else if (this.showVoice) {
      newStatus.status = onVoice;
      if (this.showCobrowse) {
        newStatus.status = onVoiceAndInCobrowse;
      }
    }

    // if state is empty, the status reverts back to a previous state
    if (isEmpty(newStatus.status)) {
      // if nothing is set, we need to go back to previous state.
      newStatus = await this.getPreviousAgentStatus();
    }

    try {
      await this.$store.dispatch('agent/changeAgentStatus', newStatus);
    } catch (err) {
      this.$aiq.notify.error(this.$t('conversation_tab.chat_panel.status_update_error_msg'));
    }

    log.debug('new Status:', newStatus);
    return newStatus;
  }
}
export default toNative(ChatPanel);
