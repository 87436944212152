<template lang="pug">
div
  managementPage.scrollable(ref="statusTable"
                :title="$t('settings_tab.admin_tab.status_tab.title')"
                :description="$t('settings_tab.admin_tab.status_tab.description')"
                @fetchData="loadList"
                :loadWithQuery="loadWithQuery"
                @openEditor="openEditor"
                class-prefix="statusTable"
                :creatable="canView('/settings/agents/status/new')"
                :creationTooltipContent="$t('settings_tab.admin_tab.status_tab.add_tooltip')")

    router-view(v-slot:editor)

    template(v-slot:body)
      aiq-table.main-contents(:data="tableData" v-loading="loading")
        aiq-table-column(:label="$t('settings_tab.admin_tab.status_tab.fields.status.label')",
                      prop="statusLabel"
                      :sortable="true"
                      :sort-method="onSort('statusLabel')"
                      label-class-name="is-bordered")

        aiq-table-column(:label="$t('settings_tab.admin_tab.status_tab.fields.sub_status.label')",
                        prop="subStatusLabel",
                        :sortable="true",
                        :sort-method="onSort('subStatusLabel')"
                        label-class-name="is-bordered")

        aiq-table-column(:label="$t('settings_tab.admin_tab.status_tab.fields.selectable.label')",
                        label-class-name="is-bordered")
          template(v-slot:default='scope')
            aiq-checkbox.toggle-selectable(:model-value="scope.row.selectable"
                                          :disabled="!scope.row.isSelectableEnabled"
                                          @change="handleSelectableChange(scope.row)")

        aiq-table-column(v-if="canView('/settings/agents/status/:status')" width="71", align="center", label-class-name="is-bordered")
          template(v-slot:default='scope')
            aiq-button.edit-btn(link type="primary" size="small", @click="openEditor(scope.row)" :disabled="!scope.row.editable") {{ $t('settings_tab.admin_tab.status_tab.fields.edit.content') }}
  edit-status(v-model="isModalVisible"
              :record="selectedStatus"
              @reloadList="loadList"
              @closeEditor="closeEditor")
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import EditStatus from './Edit.vue';

export default {
  name: 'ManageAgentStatus',
  components: {
    ManagementPage,
    EditStatus,
  },
  computed: {
    ...mapState({
      agentStatuses: state => state.settings.agentStatuses,
    }),
  },
  data() {
    const loadWithQuery = get(this.$route, 'query.query');

    return {
      loading: true,
      filteredAgentStatuses: {},
      tableData: [],
      loadWithQuery,
      isModalVisible: false,
      selectedStatus: null,
    };
  },
  methods: {
    openEditor(record) {
      if (record) {
        this.selectedStatus = record;
      }
      this.isModalVisible = true;
    },
    closeEditor() {
      this.isModalVisible = false;
      this.selectedStatus = null;
    },
    async loadList() {
      try {
        const agentStatuses = await this.$store.dispatch('settings/getSetting', { name: 'agent_status', stateName: 'agentStatuses' });  
        this.filteredAgentStatuses = agentStatuses;

        this.loading = false;
        return agentStatuses;
      } catch (err) {
        this.$aiq.notify.error(this.$t('settings_tab.admin_tab.status_tab.fetch_error_msg'));
      }
    },
    flattenAgentStatuses(statuses) {
      const flatAgentStatuses = [];
      for (const agentStatus of statuses) {
        if (!agentStatus.skipParent) {
          flatAgentStatuses.push({
            path: { status: agentStatus.status },
            statusLabel: agentStatus.label,
            subStatusLabel: null,
            editable: agentStatus.editable,
            selectable: agentStatus.selectable,
            isSelectableEnabled: true,
          });
        }
        for (const agentSubStatus of agentStatus.subStatuses) {
          flatAgentStatuses.push({
            path: { status: agentStatus.status, subStatus: agentSubStatus.status },
            statusLabel: agentStatus.label,
            subStatusLabel: agentSubStatus.label,
            editable: agentSubStatus.editable,
            selectable: agentSubStatus.selectable,
            isSelectableEnabled: agentSubStatus.editable,
          });
        }
      }
      return flatAgentStatuses;
    },
    handleSelectableChange: debounce(async function handleSelectableChange(record) {
      try {
        const updatedAgentStatuses = this.updateStatusSelectable(record.path);        
        await this.$store.dispatch('settings/updateSetting', {
          name: 'agent_status',
          payload: updatedAgentStatuses,
          stateName: 'agentStatuses'
        });

        await this.loadList();
      } catch (err) {
        this.$aiq.notify.error(this.$t('settings_tab.admin_tab.status_tab.update_error_msg'));
      }
    }, 100), // eslint-disable-line no-magic-numbers
    updateStatusSelectable(recordPath) {
      const agentStatuses = cloneDeep(this.agentStatuses);
      const updatedStatus = agentStatuses.statuses.find(s => s.status === recordPath.status);
      if (has(recordPath, 'subStatus')) {
        const updatedSubStatus = updatedStatus.subStatuses.find(s => s.status === recordPath.subStatus);
        updatedSubStatus.selectable = !updatedSubStatus.selectable;
      } else {
        updatedStatus.selectable = !updatedStatus.selectable;
      }
      return agentStatuses;
    },
    onSort(column) {
      return (a, b) => {
        const aValue = get(a, column, '');
        const normalizedAValue = isNull(aValue) ? '' : aValue.toLowerCase();
        const bValue = get(b, column, '');
        const normalizedBValue = isNull(bValue) ? '' : bValue.toLowerCase();
        return normalizedAValue <= normalizedBValue ? 1 : -1;
      };
    },
    search(data, searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return data.filter(r => r.statusLabel.toLowerCase().includes(lowerCaseSearchTerm) || !isNull(r.subStatusLabel) && r.subStatusLabel.toLowerCase().includes(lowerCaseSearchTerm));
    },
  },
  watch: {
    'filteredAgentStatuses.statuses'(value) {
      const flatAgentStatuses = this.flattenAgentStatuses(value);
      const searchTerm = get(this.$route, 'query.query');

      if (searchTerm) {
        this.tableData = this.search(flatAgentStatuses, searchTerm);
        return;
      }

      this.tableData = flatAgentStatuses;
    },
  }
};
</script>

<style lang="scss" scoped>
.toggle-selectable {
  height: 18px;
}

.edit-btn:focus {
  background: #fff; 
  border-color:#fff;
  color: #73A4FF;
}
</style>