<template lang="pug">
managementPage.scrollable(ref="quickResponsesTable"
              :title="$t('settings_tab.admin_tab.quick_response_tab.title')"
              :description="$t('settings_tab.admin_tab.quick_response_tab.intro')"
              @fetchData="loadList"
              @openEditor="openEditor"
              class-prefix="quickResponsesTable"
              :searchPageSize="searchPageSize",
              :showTotals="true"
              :pagination="pagination"
              :loadWithQuery="loadWithQuery"
              :creatable="canView('/settings/agents/quick_responses/new')"
              :creationTooltipContent="$t('settings_tab.admin_tab.quick_response_tab.add_quick_response_tooltip')")

  router-view(v-slot:editor)

  template(v-slot:body)
    aiq-table.main-contents(:data="items" v-loading="loading")

      aiq-table-column(:label="$t('settings_tab.admin_tab.quick_response_tab.fields.pin.title')",
                    width="68",
                    align="center",
                    label-class-name="is-bordered")
        template(v-slot:default='scope')
          aiq-tooltip(effect="dark", placement="top", :content="$t('settings_tab.admin_tab.quick_response_tab.fields.pin.tooltip')", :show-after="500")
            aiq-checkbox(:model-value="scope.row.is_pinned"
                        @change="handlePinChange(scope.row)")

      aiq-table-column(:label="$t('settings_tab.admin_tab.quick_response_tab.fields.title.title')",
                      prop="title",
                      :sortable="true",
                      :sort-method="onSortTitle",
                      label-class-name="is-bordered")

      aiq-table-column(:label="$t('settings_tab.admin_tab.quick_response_tab.fields.teams.title')",
                      label-class-name="is-bordered")
        template(v-slot:default='scope')
          aiq-tooltip(effect="dark", placement="top", :content="$t('settings_tab.admin_tab.quick_response_tab.fields.teams.tooltip')", :show-after="500")
            aiq-select(placeholder="Select"
                        v-model="scope.row.teams"
                        value-key="id"
                        size="small"
                        :suffix-icon="Icon.CaretBottom"
                        collapse-tags
                        clearable
                        multiple
                        :reserve-keyword="false"
                        @change="handleTeamsChange(scope.row)")
              aiq-option(v-for="item in teamOptions", :key="item.id", :label="item.name", :value="item")

      aiq-table-column(v-if="canView('/settings/agents/quick_responses/:number')" width="71", align="center", label-class-name="is-bordered")
        template(v-slot:default='scope')
          aiq-button(link type="primary" size="small", @click="openEditor(scope.row)") {{ $t('settings_tab.admin_tab.quick_response_tab.edit') }}
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { TEAM_LIMIT, parse } from '@/constants';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { PAGINATION_DEFAULT } from '@/constants/pagination';

const SEARCH_PAGE_SIZE = 1000;

export default {
  name: 'ManageQuickResponses',
  components: {
    ManagementPage,
  },
  computed: {
    ...mapGetters({
      sortedTeams: 'teams/sortedTeams',
    }),
    ...mapState({
      agentProfile: state => state.agent.profile,
      items: state => state.quickresponses.quickResponses,
      selected: state => state.quickresponses.selected,
      loaded: state => state.quickresponses.agentsLoaded,
    }),
    teamOptions() {
      return this.canView('/users/filters/show_all_teams') ? this.sortedTeams : this.agentProfile.teams;
    },
  },
  data() {
    const loadWithQuery = get(this.$route, 'params.query');

    return {
      searchPageSize: SEARCH_PAGE_SIZE,
      isLoading: false,
      pagination: PAGINATION_DEFAULT,
      loading: true,
      loadWithQuery,
    };
  },
  mounted() {
    this.loadResources();
  },
  methods: {
    loadResources() {
      this.$store.dispatch('teams/getTeamsList', [{ limit: TEAM_LIMIT }]);
    },
    onSortTitle(a, b) {
      return a.title.toLowerCase().replace(/\s+/g, '') <= b.title.toLowerCase().replace(/\s+/g, '') ? 1 : -1;
    },
    openEditor(record) {
      this.record = parse(this.$store.getters['quickresponses/quickResponseModel'], record);
      this.$store.dispatch('quickresponses/selectQuickresponse', this.record);
      const recordId = this.record.id || 'new';
      this.$router.push({
        name: 'Settings.Agents.QuickResponse.Edit',
        params: {
          id: recordId,
        },
      });
    },
    async loadList({ searchTerm, searchOffset }) {
      const params = {
        limit: this.searchPageSize,
        query: searchTerm,
        offset: searchOffset,
        usage_types: ['all', 'teams'],
      };
      const response = await this.$store.dispatch('quickresponses/getQuickresponsesList', [
        params,
        searchOffset === 0,
      ]);
      this.loading = false;
      this.pagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
      return response;
    },
    handlePinChange: debounce(async function handlePinChange(record) {
      const action = record.is_pinned ? 'unpin' : 'pin';
      try {
        const { data } = await this.$store.dispatch(`quickresponses/${action}`, record.id);
        record.is_pinned = data.is_pinned;
        this.$aiq.notify.success(this.$t('settings_tab.admin_tab.quick_response_tab.updated_success'));
      } catch (ex) {
        this.$aiq.notify.error(this.$t(`settings_tab.admin_tab.quick_response_tab.unable_to_${action}`, { title: record.title }));
      }
    }, 250), // eslint-disable-line no-magic-numbers
    handleTeamsChange: debounce(async function handleTeamsChange(record) {
      try {
        const payload = { id: record.id, teams: get(record, 'teams', []).map(x => x.id) };
        const { data } = await this.$store.dispatch('quickresponses/teams', payload);
        record.teams = data.teams;
        this.$aiq.notify.success(this.$t('settings_tab.admin_tab.quick_response_tab.updated_success'));
      } catch (ex) {
        this.$aiq.notify.error(this.$t('settings_tab.admin_tab.quick_response_tab.unable_to_update_teams', { title: record.title }));
      }
    }, 500), // eslint-disable-line no-magic-numbers
  },
};
</script>

<style lang="scss" scoped>
.quick-responses-table{
  padding: 0;
}
.el-checkbox.el-tooltip__trigger {
  height: 18px;
}
</style>
