// todo register colors globally from aiq-components
const COLORS = {
  red: '#FF6384',
  orange: '#FFBE50',
  yellow: '#FFEC71',
  grey: '#D7D9DE',
  blue: '#4671B3',
  green: '#4BC0C0',
};

export const suggestedResponsesChartConfig = {
  title: 'Suggestions',
  metric: 'suggestions',
  lines: {
    suggestion_show: {
      label: 'Suggested',
      position: 1,
      color: COLORS.red,
      type: 'line',
    },
    suggestion_click: {
      label: 'Selected',
      position: 2,
      color: COLORS.orange,
      type: 'line',
    },
    suggestion_edit: {
      label: 'Edited',
      position: 3,
      color: COLORS.yellow,
      type: 'line',
    },
    suggestion_send: {
      label: 'Sent',
      position: 4,
      color: COLORS.blue,
      type: 'line',
    },
  },
  helpText:
        'Displays suggestion count by agent action.',
  maxTickLabelCount: 6
};

export const dialogsChartConfig = {
  title: 'Dialogs',
  metric: 'dialogs',
  lines: {
    dialog_success: {
      label: 'Successful',
      position: 1,
      color: COLORS.blue,
      type: 'line',
    },
    dialog_failure: {
      label: 'Failed',
      position: 2,
      color: COLORS.red,
      type: 'line',
    },
  },
  helpText:
        'Displays dialog results.',
  maxTickLabelCount: 6
};

export const workflowsChartConfig = {
  title: 'Workflows',
  metric: 'workflows',
  lines: {
    workflow_success: {
      label: 'Successful',
      position: 1,
      color: COLORS.blue,
      type: 'line',
    },
    workflow_failure: {
      label: 'Failed',
      position: 2,
      color: COLORS.red,
      type: 'line',
    },
  },
  helpText:
        'Displays workflows results.',
  maxTickLabelCount: 6
};

export const assetsChartConfig = {
  title: 'Assets',
  metric: 'kb_assets',
  lines: {
    kb_assets_show: {
      label: 'Suggested',
      position: 1,
      color: COLORS.red,
      type: 'line',
    },
    kb_assets_click: {
      label: 'Selected',
      position: 2,
      color: COLORS.orange,
      type: 'line',
    },
    kb_assets_send: {
      label: 'Sent',
      position: 3,
      color: COLORS.blue,
      type: 'line',
    },
  },
  helpText:
        'Displays asset count by agent action.',
  maxTickLabelCount: 6
};

export const documentsChartConfig = {
  title: 'Documents',
  metric: 'kb_documents',
  lines: {
    kb_documents_show: {
      label: 'Suggested',
      position: 1,
      color: COLORS.red,
      type: 'line',
    },
    kb_documents_click: {
      label: 'Selected',
      position: 2,
      color: COLORS.orange,
      type: 'line',
    },
    kb_documents_send: {
      label: 'Sent',
      position: 3,
      color: COLORS.blue,
      type: 'line',
    },
  },
  helpText:
        'Displays document count by agent action.',
  maxTickLabelCount: 6
};