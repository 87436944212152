import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';
import findLastIndex from 'lodash/findLastIndex';
import isUndefined from 'lodash/isUndefined';
import uniqBy from 'lodash/uniqBy';
import {
  Events,
  replaceSuggestionEntityTags,
  appendSenderProfile,
} from '@/libs';

import {
  DEFAULT_KB_MODE,
  getEffectiveStatus,
  isConversationsPage,
  isStateScopeMine,
  isStateChannelAll,
  KB_MODES,
  MSG_SUGGESTION_MEMORY_LIMIT,
} from '@/constants';

const isLockedByAi = conversation => {
  if (!conversation.isInProgress) {
    return false;
  }

  return get(conversation, 'locked_by.email', ' ').indexOf('bot@agentiq.com') > -1;
};


function isUnreadActiveConversation(item) {
  return item.oldest_unanswered_customer_message_at
    && item.oldest_unanswered_customer_message_at !== new Date(0).toISOString()
    && item.status === 'active';
}

const proccedConversations = (profile, scope) => (e, old) => {
  const profilesTeams = get(profile, 'teams', []).map(j => j.id);
  e.effective_status = getEffectiveStatus(e);
  e.isInProgress = e.is_locked;
  e.isLocked = e.is_locked;
  e.isStarred = has(e, 'starred') ? e.starred : get(old, 'starred', false);
  e.isAssigned = !!get(e, 'agents', []).find(j => j.id === profile.id);
  e.isNew = get(e, 'messages[0].sender_type', null) === 'customers';
  e.isToMyTeam = !!get(e, 'teams', []).find(j => profilesTeams.includes(j.id));
  e.isBotInProgress = isLockedByAi(e);
  e.lastUnansweredTimestamp = e.oldest_unanswered_customer_message_at;
  e.showTimer = isUnreadActiveConversation(e, profile, scope) && (!isLockedByAi(e) || e.queued);
  e.selected = false;

  return e;
};

function isUnread(item) {
  return item.unread_message_count > 0;
}

function updateConversationIndicator(state, list) {
  /**
   * You are in messages tab and not filtering and
   * in scope Mine and channel All => not showing indicator
   */
  const isInCorrectScope = isConversationsPage() &&
    isStateScopeMine(state) &&
    isStateChannelAll(state);
  if (isInCorrectScope && isUndefined(state.filter)) {
    return false;
  }

  /** Since we are outside of scope Mine and channel All
   * we need to look up in list if we have unread message.
   */
  const item = list.find(e => isUnread(e));
  if (item) {
    // we found unread message and we are outside of messages page or outside of mine scope
    if (!isConversationsPage() || !isStateScopeMine(state)) {
      return true;
    }

    /** We found unread message and are in scope mine and filtering,
     * we want to show indicator if unread message is not in filtered list
     */
    if (isStateScopeMine(state) && !isUndefined(state.filter)) {
      const findConv = e => state.conversations.findIndex(j => e.id !== j.id && isUnread(e));
      return list.findIndex(e => findConv(e) >= 0) >= 0;
    }

    /** We found unread message and are inside mine scope not filtering,
     * but message is from other channel than current channel
     */
    if (item.channel !== state.channel) {
      return true;
    }
    /**
     * We also want to check if there are more unread messages and if they are are from other
     * channels than in current channel so we need notify user with indicator
     */
    return list.findIndex(e => ((isUnread(e)) && (e.channel !== state.channel))) >= 0;
  }
  return false;
}

function updateSuggestionEntities({ conversation, customer, agents, suggestions }) {
  conversation.suggestions = replaceSuggestionEntityTags({
    suggestions,
    customer,
    primaryAgent: conversation.primaryAgent,
    lockedAgent: conversation.locked_by,
    otherConversationAgents: agents,
  });
}

export default {
  SET_ATTACHMENTS_HISTORY(state, attachmentsHistory) {
    state.attachmentsHistory = attachmentsHistory;
  },

  ADD_MESSAGE(state, message) {
    if (get(message, 'payload.event.action') === 'messages.mark.read') {
      return;
    }

    appendSenderProfile(message, this.state.agents);

    let messages = state.messages;
    const index = messages.findIndex(e => e.id === message.id);
    if (get(message, 'payload.message_type') === 'reply') {
      for (let i = messages.length - 1; i >= 0; i -= 1) {
        if (messages[i].id === message.payload.reply.postback.reply_to) {
          messages[i]['replyitem'] = message.payload.reply;
          break;
        }
      }
    } else if (index !== -1) {
      messages[index] = message;
    } else {
      messages.push(message);
    }
  },

  REPLACE_MESSAGE(state, newMessage) {
    appendSenderProfile(newMessage, this.state.agents.list);

    // current customer's message is in either
    // state.selected or state.pastConversations
    let messages = get(state, 'messages', []);
    let foundIndex = messages.findIndex(m => m.id === newMessage.id);
    if (foundIndex !== -1) {
      messages[foundIndex] = newMessage;
    } else {
      for (const conv of state.pastConversations) {
        messages = get(conv, 'messages', []);
        foundIndex = messages.findIndex(m => m.id === newMessage.id);
        if (foundIndex !== -1) {
          messages[foundIndex] = newMessage;
          break;
        }
      }
    }
  },

  // To be added to the history where there is already an active selected conversation
  ADD_CONVERSATIONS(state, params) {
    prepareForState(params);
    state.pastConversations.unshift(...params.conversations);
    const existingIds = new Set(state.messages.map(message => message.id));
    const newMessages = params.messages.filter(message => !existingIds.has(message.id));
    state.messages.unshift(...newMessages);
  },

  CUSTOMER_CONVERSATIONS_LOADING(state, value) {
    state.customerConversationsLoading = value;
  },

  TOTAL_CUSTOMER_MESSAGES(state, total) {
    state.totalMessagesOfCustomer = total;
  },

  TYPING_STATUS(state, [value]) {
    state.typing = value;
  },

  CLEAR_LIST(state) {
    state.conversations = [];
    state.pagination = {};
    state.loaded = false;
  },

  SET_SYSTEM_MESSAGES_DISPLAY(state, display) {
    state.systemMessagesDisplay = display;
    localStorage.setItem('systemMessagesDisplay', state.systemMessagesDisplay ? 'display' : 'hide');
  },

  UNFOCUS(state) {
    state.focused = null;
  },

  SET_FOCUS(state, customer) {
    state.focused = { customer, customer_id: customer.id };
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = !isUndefined(state.filter) ? pagination : {};
  },

  LOAD_LIST(state, [list, profile, updated = true, polling = false, activeSort = '', scope = 'all', channel = '']) {
    state.activeSort = activeSort;
    // There is a bug here where scope is 'all' even in the 'mine' tab.
    if (scope === 'mine' && channel === 'all') {
      state.newConversationIndicator = updateConversationIndicator(state, list);
    }

    /**
     * We need to update list because we are either in mine and any
     * channel(we always pull for mine/all) or in current state scope and current state channel.
     */
    if (isStateScopeMine(state) || (state.scope === scope && state.channel === channel)) {
      list = list.map(proccedConversations(profile, state.scope));
      // we are not polling and request came from UI
      if (!polling) {
        if (updated) {
          state.conversations = list;
        } else {
          state.conversations.push(...list);
        }

        return;
      }

      // we are polling and updating data when not filtering
      if (polling && isUndefined(state.filter)) {
        /**
         * We are polling and in state scope Mine => in state channel All we replace conversations
         * with full list. In state channel other than All we filter the list based on channel.
         */
        if (isStateScopeMine(state)) {
          const filteredList = list.filter(e => e.channel === state.channel);
          state.conversations = isStateChannelAll(state) ? list : filteredList;
          return;
        }
        state.conversations = list;
      }
    }
  },

  RESET_KB(state) {
    state.KBMode = cloneDeep(DEFAULT_KB_MODE);
  },

  SELECT_SUGGESTIONS(state, { type, msgId }) {
    const info = state.suggestedItemHistory[type];
    const suggestions = info.suggestionsByMsgId[msgId];
    state.KBMode = {
      name: KB_MODES.SELECTED,
      type,
      items: suggestions,
    };
  },

  // TODO (Gabe) - Remove once suggest item mutation below is used
  SET_DOCUMENTS(state, [conversation, documents]) {
    if (state.selected.id === conversation) {
      state.selected.documents = documents;
    }
  },

  SET_KB_MODE(state, payload) {
    state.KBMode = {
      ...state.KBMode,
      ...payload,
    };
  },

  SET_SUGGEST_ITEM_HISTORY_BY_MESSAGE(state, [suggestionsByMsg, type]) {
    const history = state.suggestedItemHistory[type];
    const { msgIds, suggestionsByMsgId } = history;

    suggestionsByMsg.forEach(kbSuggestion => {
      msgIds.push(kbSuggestion.message_id);
      suggestionsByMsgId[kbSuggestion.message_id] = kbSuggestion[type];
    });
  },

  SET_SUGGEST_ITEM_HISTORY(state, [, rawItems, type]) {
    if (rawItems.length === 0) { return }
    const items = rawItems;
    state.selected[type] = items;

    /**
     * TODO (Gabe) - switch once event conversation returns correct message
     * const lastMsg = conversation.messages[0];
     */
    let lastMsg;
    for (let i = 0; i < state.messages.length; i += 1) {
      const msg = state.messages[i];
      if (msg.sender_type === 'customers') {
        lastMsg = msg;
      }
    }

    if (!lastMsg) return;
    // We only want to keep the last X messages that have suggestions

    const history = state.suggestedItemHistory[type];
    const { msgIds, suggestionsByMsgId } = history;
    // We only want to keep the last X messages that have suggestions
    if (msgIds.length < MSG_SUGGESTION_MEMORY_LIMIT - 1) {
      msgIds.push(lastMsg.id);
      suggestionsByMsgId[lastMsg.id] = items;
    } else {
      // remove oldest item from beginning of array
      const oldestId = msgIds.shift().id;
      delete suggestionsByMsgId[oldestId];
    }
  },

  SET(state, [key, value]) {
    state[key] = value;
  },

  SET_SCOPE(state, scope) {
    state.scope = scope;
  },

  SET_SELECTED_TAB(state, selectedTab) {
    state['selectedTab'] = selectedTab;
  },

  SET_CHANNEL(state, channel) {
    state.channel = channel;
  },

  SET_FILTER(state, filter) {
    state.filter = filter;
  },

  UPDATE_ALL_COUNTERS(state, counters) {
    state.counters = { ...state.counters, ...counters };
  },

  UPDATE_SPECIAL_COUNTER(state, [name, value]) {
    set(state.counters, name, value);
  },

  SELECT_CONVERSATION(state,
    [{ conversations = [], messages = [], customer }, profile, agentsById, starred = false]) { // eslint-disable-line
    let shouldTagCustomerRead = false;
    state.customerReadMsg = null;

    // Attach read status to the last agent message
    for (const message of messages) {
      if (get(message, 'payload.event.action') === 'messages.mark.read'
        && get(message, 'sender_type') === 'customers' && !shouldTagCustomerRead) {
        shouldTagCustomerRead = true;
      }

      if (get(message, 'sender_type') === 'agents'
        && get(message, 'payload.message_type') !== 'event'
        && shouldTagCustomerRead
        && !state.customerReadMsg) {
        state.customerReadMsg = message; // save it to remove in the future
        state.customerReadMsg['status'] = { state: 'viewed', message: 'Read' };
        break;
      }
    }

    // Chat Panel star indicator to only apply to the last conversation
    const lastConversation = conversations[0];
    lastConversation.isStarred = starred;

    /** TODO (Gabe) - Remove check once customer is always provided
     *  Check only needed for when selected from direct url
     */

    if (customer) {
      state.selectedCustomer = customer;
      prepareForState({ conversations, messages, agentsById, customer });
    }

    state.selected = conversations.pop();
    state.pastConversations = conversations;
    state.messages = messages;
  },

  SET_NOTES(state, [notes, update = true]) {
    if (update) {
      state.notes = notes;
    } else {
      state.notes.push(...notes);
    }
  },

  SET_SCHEDULES(state, [schedules, update = true]) {
    if (update) {
      state.schedules = schedules;
    } else {
      state.schedules.push(...schedules);
    }
  },

  UPDATE_SELECTED_CONVERSATION(state, [conversation]) {
    if (state.selected.id === conversation.id) {
      state.selected = conversation;
    }
  },

  ADD_NOTE(state, [conversation, note]) {
    if (state.selected.id === conversation) {
      state.notes.unshift(note);
    }
  },

  REMOVE_NOTE(state, [conversation, note]) {
    if (state.selected.id !== conversation) {
      return;
    }

    state.notes = state.notes.filter(e => e.id !== note);
  },

  STAR_CONVERSATION(state, [id, value]) {
    if (state.selected.id === id) {
      set(state.selected, 'isStarred', value);
    }

    state.conversations = state.conversations.map(e => {
      if (e.id === id) {
        e.isStarred = value;
      }

      return e;
    });
  },

  MARK_AS_LOADED(state, amount) {
    state.loaded = amount <= state.conversations.length;
  },

  UPDATE_TEAMS(state, [conversation, teams]) {
    if (state.selected.id === conversation) {
      state.selected.teams = teams;
    }
  },

  UPDATE_TAGS(state, [conversation, tags]) {
    if (state.selected.id === conversation) {
      state.selected.tags = tags;
    }
  },

  UPDATE_CATEGORIES(state, [conversation, categories]) {
    if (state.selected.id === conversation) {
      state.selected.categories = categories;
    }
  },

  // todo - change param names to conversationId
  UPDATE_AGENTS(state, [conversationId, agents, customerId]) {
    if (!state.conversationAgents) {
      state.conversationAgents = {};
    }

    if (!state.conversationAgents[conversationId]) {
      state.conversationAgents[conversationId] = [];
    }

    state.conversationAgents[conversationId] = agents;
    // Only update the agent list if this message applies to the
    // a conversation held in the currently selected customer.
    if (get(state, 'selected.customer_id') === customerId) {
      // TODO (Gabe) - consolidate with conversationAgents.
      state.selected.agents = agents;
    }
  },

  UPDATE_ASSETS(state, [conversation, assets]) {
    if (state.selected.id === conversation) {
      state.selected.assets = assets;
    }
  },

  UPDATE_DOCUMENTS(state, [conversation, documents]) {
    if (state.selected.id === conversation) {
      state.selected.documents = documents;
    }
  },

  REMOVE_PRIMARY_AGENT(state, [conversationId, customer]) {
    const { primaryAgent, primary_agent, ..._customer } = customer;
    if (state.selected.id === conversationId) {
      state.selected = { ...state.selected, customer: _customer };
      state.selectedCustomer = { ..._customer };
    }

    state.conversations = state.conversations.map(e => {
      if (e.id === conversationId) {
        const DEFAULT_PRIMARY_AGENT_OBJ = {
          canLock: true,
          firstName: '',
          maxLocks: 3,
        };

        e.primaryAgent = DEFAULT_PRIMARY_AGENT_OBJ;
        e.customer = customer;
      }

      return e;
    });
  },

  UPDATE_CUSTOMER(state, [customer]) {
    const customerId = get(customer, 'id');
    if (get(state, 'selected.customer_id') === customerId) {
      state.selected = { ...state.selected, customer };
      state.selectedCustomer = { ...customer };
    }

    state.conversations = state.conversations.map(e => {
      if (e.customer_id === customerId) {
        e.customer = customer;
      }

      return e;
    });
  },

  UPDATE_CUSTOMER_PRIMARY_AGENT(state, [customer, agent]) {
    if (get(state, 'selected.customer_id', -1) === get(customer, 'id')) {
      state.selected.primaryAgent = agent;
    }
  },

  CLOSE_CONVERSATION(state, conversation) {
    const id = get(conversation, 'id', -1);
    if (state.selected.id === id) {
      state.selected = {};
    }
  },

  UPDATE_CONVERSATION(state, [conversation, profile]) {
    if (state.conversations.find(e => e.id === conversation.id)) {
      state.conversations = state.conversations.map(e => {
        if (e.id === conversation.id) {
          return proccedConversations(profile, state.scope)(conversation, e);
        }
        return e;
      });
    }
  },

  UPDATE_LOCK_STATUS(state, [conversation, profile]) {
    const id = get(conversation, 'id', -1);
    if (state.selected.id === id) {
      state.selected = { ...state.selected, locked_by: get(conversation, 'locked_by', null) };
    }
    state.conversations = state.conversations.map(e => {
      if (e.id === id) {
        const merged = merge(omit(e, ['locked_by', 'isLocked']), conversation);
        return proccedConversations(profile, state.scope)(merged);
      }
      return e;
    });
  },

  UPDATE_SUGGESTIONS_LIST(state, [id, suggestions]) {
    if (state.selected.id === id) {
      updateSuggestionEntities({
        conversation: state.selected,
        customer: get(state, 'selectedCustomer', {}),
        agents: get(state, 'selected.agents', []),
        suggestions,
      });
      Events.emit('open.suggestions');
    }
  },

  SET_OPENED_SIDEBAR_TAB(state, tab) {
    state.openedSidebarTab = tab;
  },

  SET_QUICK_RESPONSES_LIST(state, { list, isNew }) {
    state.quickResponses = isNew ? [...list] : uniqBy(state.quickResponses.concat(list), 'id');
  },

  UPDATE_NEW_CONVERSATION_INDICATOR(state, value) {
    state.newConversationIndicator = value;
  },

  IS_COVERSATION_OPEN(state, value) {
    state.isConversationOpen = value;
  },

  IS_VIDEO_CONTEXT(state, value) {
    state.isVideoContext = value;
  },

  IS_COBROWSE_CONTEXT(state, value) {
    state.isCobrowseContext = value;
  },

  IS_VOICE_CONTEXT(state, value) {
    state.isVoiceCallContext = value;
  },

  MARK_AS_READ(state, [conversation]) {
    if (state.conversations.find(e => e.id === conversation.id)) {
      state.conversations = state.conversations.map(e => {
        if (e.id === conversation.id) {
          e.unread_message_count = 0;
          e.effective_status = 'inactive';
        }
        return e;
      });
    }
  },

  UPDATE_POLL_LIMIT(state, value) {
    state.limit = value;
  },

  UPDATE_CUSTOMER_READ(state) {
    if (state.customerReadMsg) {
      delete state.customerReadMsg.status;
      state.customerReadMsg['status'] = null;
      state.customerReadMsg = null;
    }

    // reverse looping
    const i = findLastIndex(get(state, 'messages', []), (m) => {
      if (get(m, 'sender_type') === 'agents') {
        return true;
      }
      return false;
    });

    if (i !== -1) {
      state.customerReadMsg = state.messages[i];
      state.customerReadMsg['status'] = { state: 'viewed', message: 'Read' };
    }
  },

  SET_CHANNELS(state, value) {
    state.channels = value;
  },

  TOGGLE_IS_SUGGESTIONS_OPENED(state) {
    state.isSuggestionsOpened = !state.isSuggestionsOpened;
    localStorage.setItem('isSuggestionsOpened', state.isSuggestionsOpened);
  },

  SET_IS_SUGGESTIONS_OPENED(state, value) {
    state.isSuggestionsOpened = value;
    localStorage.setItem('isSuggestionsOpened', value);
  },

  SET_CUTOMER_FROM_ROUTE(state, value) {
    state.selectedCustomer = value;
  },

  SET_IS_TIMESTAMP_ABSOLUTE(state, value) {
    state.isTimestampAbsolute = value;
    localStorage.setItem('isTimestampAbsolute', state.isTimestampAbsolute);
  },

  SET_LAST_FEEDBACK_INFO(state, value) {
    state.selectedLastFeedbackInfo = value;
  },
};

function prepareForState({ conversations, messages, agentsById, customer }) {
  const SENDER_TYPES = {
    AGENT: 'agents',
    CUSTOMER: 'customers',
  };
  conversations.reverse();
  for (const conversation of conversations) {
    const suggestions = get(conversation, 'suggestions', []);
    updateSuggestionEntities({
      conversation,
      customer,
      agents: conversation.agents,
      suggestions,
    });
  }
  messages.reverse();
  for (const message of messages) {
    const { sender_type } = message;
    if (sender_type === SENDER_TYPES.AGENT) {
      message.sender = agentsById[message.sender_id];
    } else if (sender_type === SENDER_TYPES.CUSTOMER) {
      message.sender = customer;
    }
  }
}
