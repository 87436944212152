<template lang="pug">
modal-editor.edit-action-modal(:visible="visible"
                              isRecordValid
                              :title="title"
                              :useDefaultFooter="false"
                              :afterClose="navigateAway"
                              :skipConfirmation="!isRecordDirty"
                              ref="modalEditor")
    template(v-slot:content)
      .card-title-container
        .field-container.is-required
          label {{ toLabelText }}
          .actions_to_list.scroll
            aiq-tag(v-for="item in itemsToShow", :key="item.id", closable, close-transition, @close="remove(item.id)") {{ item[itemNameKey] }}
            aiq-tag.show_more(v-if="shouldShowMoreButton", @click="onClickShowMore") {{ showMoreButtonText }}
        .field-container(v-if="!hideActionName")
          label {{ actionLabelText }}
          aiq-input(size="small"
                    placeholder="Action Name"
                    name="actionName"
                    :model-value="actionValue"
                    :disabled="true")
        .field-container(v-if="showOptions" v-bind:class="{ 'is-required': requireOption}")
          label {{ optionsLabelText }}
          aiq-select(placeholder="Select"
                     size="small"
                     v-model="selectedOption"
                     :clearable="allowEmptyOption"
                     name="optionsField"
                     :filter-method="onSelectInputChange")
            aiq-option(v-for="(option, index) in filteredOptionsValues"
                      :key="option.id || `${option}-${index}`"
                      :label="option.label || option"
                      :value="option.id || option")
              agent-status(v-if="useAgentStatus" :available="option.status" :subStatus="option.subStatus" :show-label="true")
        .field-container(v-if="showMultiOptions" v-bind:class="{ 'is-required': requireMultiOptions}")
          label {{ multiOptionsLabelText }}
          aiq-select(placeholder="Select"
                     size="small"
                     multiple
                     v-model="selectedMultiOption"
                     name="multiOptionsField"
                    :filter-method="onMultiSelectInputChange"
                    :reserve-keyword="false")
            aiq-option(v-for="(option, index) in filteredMultiOptionsValues"
                      :key="`${option}-${index}`"
                      :label="option"
                      :value="option")
        .field-container(v-if="showMessage" v-bind:class="{ 'is-required': requireMessage}")
          label {{ messageLabelText }}
          .input-message
            aiq-upload(accept="image/*,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint"
                      v-if="showAttachmentButton"
                      :disabled="!imagesOrDocsUploadEnabled"
                      :action="UPLOAD_FILE_URL"
                      :class="{ greyed: !imagesOrDocsUploadEnabled }"
                      :before-upload="onBeforeUpload"
                      :headers="headers"
                      :show-file-list="false"
                      :on-error="onUploadFailure"
                      :on-success="onUploadSuccess")

              aiq-button(class="paperclip-buton")
                font-awesome-icon(v-if="imageOrPdfUploading"
                                icon="spinner"
                                class="font-awesome-spinner"
                                spin)
                i.iq-ico-picture-or-pdf(v-else)   

            aiq-input(size="small"
                      placeholder="Enter Message"
                      name="messageName"
                      v-model="message"
                      v-bind:class="{ 'no-border-botton-radius': hasAttachments }")

          .items-attached(:class="{ 'is-hidden': !hasAttachments }")
            .images-content
              .image-item.attached-file(v-for="item in attachedFiles")
                i.iq-ico-close.image-remove(@click="removeFile(item)")
                aiq-image.image-item_img-preview(v-if="isFileImage(item)"
                                                :key="item.id"
                                                :file="item")
                .document-icon(v-else)
                  DocumentIcon
        aiq-row(v-if="showExpirationMessage" :gutter="20" class="expiration-message")
          aiq-col(:sm="14")
            .field-container
              label Message Expiration Date
              aiq-switch(active-text="",
                        inactive-text="",
                        style="--aiq-switch-on-color: #59b4FF; --aiq-switch-off-color: #C0CCDA"
                        name="messageExpirationDate"
                        v-model="isExpirationMessage") 
          aiq-col(:sm="10" v-if="isExpirationMessage")
            aiq-date-picker(v-model="expirationMessageDate"
                      type="date"
                      size="medium"
                      popper-class="date-picker-popup"
                      style="width: 100%;"
                      :clearable="true"
                      placeholder="Select Date"
                      :disabled-date="datePickerOptions.disabledDate"
                      :format="DateFormat"
                      class="normal-date-picker-end-popup")
      .el-dialog__footer
        .footer-container
          p.required-notice * is required
          aiq-button(@click="navigateAway") Cancel
          aiq-button(:disabled="!canCommitAction"
                    type="primary"
                    @click="commitAction") {{ actionButtonText }}
</template>

<script>

import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AgentStatus from '@/components/AgentStatus.vue';
import DocumentIcon from '@/assets/svg/document.vue';
import Editor from '@/components/Editor/Editor.vue';
import { hosts } from '@/config/api.routes';
import { CHANNELS } from '@/constants';
import { getLocalDateFormat } from '@/libs';

const DEFAULT_ITEMS_TO_SHOW = 4;
const UPLOAD_FILE_URL = `${hosts.management}files/upload`;

export default {
  extends: Editor,
  components: {
    DocumentIcon,
    AgentStatus,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      selectedOption: { $autoDirty: true },
      selectedMultiOption: { $autoDirty: true },
      message: { $autoDirty: true },
      isExpirationMessage: { $autoDirty: true },
      record: {
        name: { required, $autoDirty: true },
        description: { $autoDirty: true },
        agents: { $autoDirty: true },
        watcherAgents: { $autoDirty: true },
        keywords: { $autoDirty: true },
      },
    };
  },
  props: {
    title: {
      type: String,
      default: 'Actions',
    },
    actionButtonText: {
      type: String,
      default: 'Send',
    },
    items: {
      type: Object,
      default: () => ({}),
    },
    itemNameKey: {
      type: String,
      default: 'fullName',
    },
    toLabelText: {
      type: String,
      default: 'To:',
    },
    actionLabelText: {
      type: String,
      default: 'Action',
    },
    actionValue: {
      type: String,
    },
    showMessage: {
      type: Boolean,
    },
    showAttachmentButton: {
      type: Boolean,
    },
    requireOption: {
      type: Boolean,
      default: false,
    },
    requireMultiOptions: {
      type: Boolean,
      default: false,
    },
    requireMessage: {
      type: Boolean,
      default: false,
    },
    messageLabelText: {
      type: String,
      default: 'Message:',
    },
    messageText: {
      type: String,
      default: '',
    },
    optionsLabelText: {
      type: String,
      default: 'Survey Type:',
    },
    multiOptionsLabelText: {
      type: String,
      default: '',
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    allowEmptyOption: {
      type: Boolean,
      default: false,
    },
    showMultiOptions: {
      type: Boolean,
      default: false,
    },
    optionsValues: {
      type: Array,
      default: () => [],
    },
    multiOptionsValues: {
      type: Array,
      default: () => [],
    },
    hideActionName: {
      type: Boolean,
      default: false,
    },
    useAgentStatus: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedOption() {
      this.isExpirationMessage = false;
      this.expirationMessageDate = null;
    },
  },
  computed: {
    itemsLength() {
      return Object.keys(this.items).length;
    },
    DateFormat() {
      return getLocalDateFormat();
    },
    canCommitAction() {
      return !!this.itemsLength
      && (this.requireMessage ? this.message : true)
      && (this.requireOption ? this.selectedOption : true)
      && (this.requireMultiOptions ? Boolean(this.selectedMultiOption.length) : true)
      && (this.isExpirationMessage ? this.expirationMessageDate : true);
    },
    shouldShowMoreButton() {
      return (!this.showMoreClicked && this.itemsLength > DEFAULT_ITEMS_TO_SHOW);
    },
    showMoreButtonText() {
      return `${this.itemsLength - DEFAULT_ITEMS_TO_SHOW} more`;
    },
    showExpirationMessage() {
      return this.selectedOption === CHANNELS.WEBCHAT;
    },
    itemsToShow() {
      if (this.showMoreClicked || this.itemsLength <= DEFAULT_ITEMS_TO_SHOW) {
        return this.items;
      }
      const chosenKeys = Object.keys(this.items).slice(0, DEFAULT_ITEMS_TO_SHOW);
      const selectedItems = {};
      for (const key of chosenKeys) {
        selectedItems[key] = this.items[key];
      }
      return selectedItems;
    },
    files() {
      const files = this.attachedFiles.map(file => ({ message_type: 'file', file }));

      if (!isEmpty(files)) {
        return files;
      }

      return;
    },
    imagesOrDocsUploadEnabled() {
      return !this.imageOrPdfUploading;
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    headers() {
      return {
        Authorization: this.$store.getters['agent/authToken'],
      };
    },
    datePickerOptions() {
      return {
        disabledDate: (time) => time.getTime() < moment().endOf('day'),
      };
    },
  },
  data() {
    return {
      message: this.messageText,
      isExpirationMessage: false,
      expirationMessageDate: null,
      showMoreClicked: false,
      selectedOption: '',
      selectedMultiOption: [],
      attachedFiles: [],
      imageOrPdfUploading: false,
      UPLOAD_FILE_URL,
      filteredMultiOptionsValues: this.multiOptionsValues,
      filteredOptionsValues: this.optionsValues,
    };
  },
  emits: ['closeActionPanel', 'commitAction'],
  methods: {
    onSelectInputChange(inputValue) {
      this.filteredOptionsValues = this.optionsValues.filter(o => {
        const optionValue = get(o, 'label', o);
        return optionValue.toLowerCase().includes(inputValue.toLowerCase());
      });
    },
    onMultiSelectInputChange(inputValue) {
      this.filteredMultiOptionsValues = this.multiOptionsValues.filter(optionValue => optionValue.toLowerCase().includes(inputValue.toLowerCase()));
    },
    remove(id) {
      delete this.items[id];
    },
    navigateAway() {
      this.$emit('closeActionPanel');
    },
    commitAction() {
      this.$emit('commitAction', {
        actionValue: this.actionValue,
        items: this.items,
        message: this.message,
        files: this.files,
        selectedOption: this.selectedOption,
        selectedMultiOption: this.selectedMultiOption,
        expirationMessageDate: this.isExpirationMessage ? this.expirationMessageDate : null,
      });
      this.navigateAway();
    },
    // overwriting parent function
    resolveRecord() {
      return Promise.resolve(this.record);
    },
    onClickShowMore() {
      this.showMoreClicked = true;
    },
    isFileImage(file) {
      return get(file, 'mime', '').includes('image');
    },
    removeFile(file) {
      this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1);
    },
    onBeforeUpload() {
      this.imageOrPdfUploading = true;
    },
    onUploadSuccess(file) {
      this.attachedFiles.push(file);
      this.imageOrPdfUploading = false;
    },
    onUploadFailure() {
      this.$aiq.notify.error('Unable to upload. Please try again.');
      this.imageOrPdfUploading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/attach-files.scss";

.actions_to_list {
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  transition: border 0.3s;
  min-height: 30px;
  max-height: 85px;
  margin-bottom: 10px;
  cursor: text;

  &:focus-within {
    border-color: #20a0ff;
  }

  .el-tag {
    height: auto;
    min-height: 20px;
    margin: 4px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;

    .el-tag__close {
      top: 3px;
      width: 12px;
      height: 12px;
      margin-left: 8px;
      color: #BFCBD9;
      font-size: 12px;
    }
  }

  .show_more {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    color: #000000;
    cursor: pointer;
  }

  img {
    width: 28px;
    height: 28px;
    margin-right: 4px;
    border-radius: 50%;
  }
  .agent-name {
    color: #324057;
    font-size: 12px;
    font-weight: 300;
  }
  .agent-availability-indicator {
    left: 18px;
    width: 8px;
    height: 8px;
  }
}
:deep(.no-border-botton-radius > input) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.items-attached {
  margin-top: -16px;
  margin-left: 32px;
}
.paperclip-buton {
  padding-left: 5px;
}
.greyed {
  opacity: 0.2;
}
.input-message {
  display: flex;
  
 .el-button {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    z-index: 20;
  }

  .el-button + .el-button {
    margin-left: 0px;
  }

  .el-upload {
    height: 100%;
    .el-button {
      height: 100%;
      padding-top: 15px !important;
    }
  }

  .iq-ico-picture-or-pdf {
    width: 12px;
    height: 12px;
  }
}
.expiration-message {
  height: 54px;
  display: flex;
  .el-col {
    display: flex;
    align-items: center;
  }
  .el-switch {
    margin-left: 10px;
  }
}
.font-awesome-spinner {
  width: 12px;
}
</style>
