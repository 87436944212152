<template lang="pug">
.agent-status
  aiq-status-indicator(:status="available" :show-all-states="true" :placement="placement")
  span.status-label(v-if="showLabel") {{ label }}
</template>

<script>
import { AGENT_STATUS, OUT_OF_OFFICE_STATUS } from '@/constants';
import { mapState } from 'vuex';

export default {
  name: 'agentStatus',
  props: {
    available: {
      type: String,
      default: AGENT_STATUS.Away.field,
    },
    subStatus: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  computed: {
    ...mapState({
      includeSubstatus: state => state.featureFlags.FEATURE_FLAGS.SETTINGS_AGENT_STATUS,
      agentStatuses: state => state.settings.agentStatuses,
    }),
    status() {
      return Object.values({ ...AGENT_STATUS, 'Away/Out Of Office': OUT_OF_OFFICE_STATUS })
        .find(status => status.field === this.available);
    },
    label() {
      if (this.includeSubstatus) {
        const statusObj = this.agentStatuses.statuses.find(s => s.status === this.available);
        const subStatusObj = statusObj.subStatuses.find(s => s.status === this.subStatus);
        return `${statusObj.label}${subStatusObj ? ` | ${subStatusObj.label}` : ''}`;
      }

      return this.status.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-status {
  display: inline;
  padding: 0px;
  margin: 0px;
}

.status-label {
  padding: 5px;
  display: unset !important;
}
</style>
