import axios from 'axios';
import get from 'lodash/get';
import concat from 'lodash/concat';
import { getUrl } from '@/config/api.routes';
import { useTimeZone } from '@/libs';

export default {
  namespaced: true,

  state: {
    survey: {
      // TODO: Currently there is no way switch survey between nps and csat
      surveyType: 'csat',
      feedbackItems: [],
    },
  },

  getters: {},

  mutations: {
    SELECT_METRIC(state, item) {
      state.selected = item;
    },

    SET_FEEDBACK(state, feedbackItems = []) {
      state.survey.feedbackItems = feedbackItems;
    },

    EXTEND_FEEDBACK(state, feedbackItems = []) {
      state.survey.feedbackItems = concat(get(state, 'survey.feedbackItems', []),
        feedbackItems);
    },

    SET_SURVEY_TYPE(state, surveyType) {
      state.survey.surveyType = surveyType;
    },
  },

  actions: {
    async getMetric(context, [metric, config]) {
      const timezone = await useTimeZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone, 
        this.dispatch,
        context.rootState.configs);
      const payload = { ...config, time_zone: timezone };
      return axios.post(getUrl('metrics', metric), payload)
        .then(data => {
          const metricData = data.data || {};
          return metricData;
        });
    },
    getTeams() {
      return axios.post(getUrl('teamsList'))
        .then(data => data || {});
    },
    
    async getPlatformCommsList({_commit}, [metric, config]) {
      return await axios.post(getUrl('metrics', metric), config)
        .then(data => {
          return data.data;
        });
   
    },
    getFeedbackType({ commit, dispatch }) {
      return dispatch('configs/getPostChatSurvey', null, { root: true })
        .then(data => {
          const surveyType = get(data, 'survey_type', 'csat');
          commit('SET_SURVEY_TYPE', surveyType);
          return surveyType;
        });
    },
    async getFeedbackList({ commit, state }, { params, extend_state }) {
      params = Object.assign({}, params, { survey_type: state.survey.surveyType });
      const data = await axios.get(getUrl('metrics', 'customer_feedback'), { params });
      if (extend_state) {
        commit('EXTEND_FEEDBACK', data.data.models);
      } else {
        commit('SET_FEEDBACK', data.data.models);
      }
      return data.data;
    },
  },

};
