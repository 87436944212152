import get from 'lodash/get';
import featureFlags from '@/store/modules/featureFlags';
import Pulse from './Pulse.vue';
import Now from './Now/Now.vue';
import LastHour from './LastHour/LastHour.vue';
import Today from './Today/Today.vue';
import Selections from './Insights/Selections.vue';

const showPulseToday = get(featureFlags, 'state.FEATURE_FLAGS.PULSE_TODAY', true);
const showPulseSelections = get(featureFlags, 'state.FEATURE_FLAGS.PULSE_SELECTIONS', false);

const rootRoute = {
  path: '/pulse',
  name: 'Pulse',
  component: Pulse,
  meta: {
    auth: true,
  },
  props: {
    baseResource: '/pulse',
  },
};

const commonChildMeta = {
  auth: true,
  resource: rootRoute.path,
  parentName: rootRoute.name,
};

const childRoutes = [
  {
    path: 'now',
    name: 'Pulse.Now',
    component: Now,
    meta: {
      label: 'Now',
      ...commonChildMeta,
    },
  },
  ...(showPulseToday ? [{
    path: 'today',
    name: 'Pulse.Today',
    component: Today,
    meta: {
      label: 'Today',
      ...commonChildMeta,
    },
  }] : [
    {
      path: 'today',
      name: 'Pulse.Today',
      component: LastHour,
      meta: {
        label: 'Last Hour',
        ...commonChildMeta,
      },
    }]),
];

if (showPulseSelections) childRoutes.push({
  path: 'insights',
  name: 'Pulse.Insights',
  component: Selections,
  meta: {
    label: 'Insights',
    ...commonChildMeta,
  },
});

rootRoute.props.children = childRoutes;

export default {
  ...rootRoute,
  children: childRoutes,
};
