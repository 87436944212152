// Look for permissions. If it exists, enable it. If the permission has a '*' then enable
// it for everyone.
const permissionsMapping = {
  'Update Primary Agent': '[dashboard]/customers/actions/update_primary_agent', // Okay to use remove_agent permission for update.
  'Send Message': '[dashboard]/customers/actions/send_message',
  'Request Feedback': '[dashboard]/customers/actions/send_survey',
  'Update Segments': '[dashboard]/customers/actions/update_segment',
  'Close Conversation': '[dashboard]/customers/actions/close_conversation',
  'Update Assigned Agents': '[dashboard]/customers/actions/update_agents',
};

export const filterActionsByPermissions = (actions = {}, permissions = {}) => Object.keys(actions).filter(k => permissionsMapping[k] === '*' || permissions[permissionsMapping[k]]);

const usersPermissionsMapping = {
  'Mark Away': '[dashboard]/users/actions/mark_away',
  'Update Agent Status': '[dashboard]/users/actions/mark_away',
  'Send Notification': '[dashboard]/users/actions/send_notification',
};

export const filterUsersActionsByPermissions = (actions = {}, permissions = {}) => Object.keys(actions).filter(k => usersPermissionsMapping[k] === '*' || permissions[usersPermissionsMapping[k]]);
