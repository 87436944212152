import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import actions from './actions';
import mutations from './mutations';
import {
  CONVERSATION_LIMIT,
  DEFAULT_CHANNEL,
  DEFAULT_KB_MODE,
  DEFAULT_SCOPE,
  DEFAULT_POLL_INTERVAL,
  SIDE_PANEL_TAB_NAMES,
  DEFAULT_ATTACHMENTS_HISTORY,
} from '@/constants';
import { BOT_EMAIL } from '@/constants/agent';

const loadSystemMessagesDisplay = () => {
  const systemMessagesDisplay = localStorage.getItem('systemMessagesDisplay');
  return systemMessagesDisplay !== null ? systemMessagesDisplay === 'display' : false;
};

const loadIsSuggestionsOpened = () => {
  const isSuggestionsOpened = localStorage.getItem('isSuggestionsOpened');
  return JSON.parse(isSuggestionsOpened);
};

const loadIsTimestampAbsolute = () => {
  const isTimestampAbsolute = localStorage.getItem('isTimestampAbsolute');
  return !!JSON.parse(isTimestampAbsolute);
};

export default {
  namespaced: true,
  state: {
    systemMessagesDisplay: loadSystemMessagesDisplay(),
    newConversationIndicator: false,
    // Conversations in tab
    conversations: [],
    pagination: {},
    // TODO (Gabe) Refactor naming/structure of ConversationTimerInterval
    scopedConversationTimerInterval: null,
    /**
     * Todo - clean selected conversation up so there aren't multiple sources of truth.
     * Represents the most recent conversation of the selected customer
     */
    selected: {},
    pastConversations: [],
    messages: [],
    customerReadMsg: null,
    selectedCustomer: null,
    selectedLastFeedbackInfo: null,
    notes: [],
    schedules: [],
    counters: {
      all: 0,
      mine: 0,
      unread: 0,
      starred: 0,
    },
    loaded: false,
    activeSort: '',
    scope: DEFAULT_SCOPE,
    // filters:
    channel: DEFAULT_CHANNEL,
    channels: [],
    filter: null,
    openedSidebarTab: SIDE_PANEL_TAB_NAMES.CUSTOMER_INFO,
    quickResponses: [],
    poll: null,
    focused: null,
    pollInterval: DEFAULT_POLL_INTERVAL,
    limit: CONVERSATION_LIMIT,

    // For the active conversation
    totalMessagesOfCustomer: 1,
    typing: false,
    customerConversationsLoading: false,
    isConversationOpen: false,
    isVideoContext: false,
    isCobrowseContext: false,
    isVoiceCallContext: false,

    // Todo (Gabe) - namespace these into selected
    KBMode: cloneDeep(DEFAULT_KB_MODE),
    suggestedItemHistory: {
      assets: {
        msgIds: [],
        suggestionsByMsgId: {},
      },
      documents: {
        msgIds: [],
        suggestionsByMsgId: {},
      },
    },

    // Attachments History
    attachmentsHistory: cloneDeep(DEFAULT_ATTACHMENTS_HISTORY),

    isSuggestionsOpened: loadIsSuggestionsOpened(),

    isTimestampAbsolute: loadIsTimestampAbsolute(),
  },
  getters: {
    isRealtimeContext: state => {
      const { isVideoContext, isCobrowseContext, isVoiceCallContext } = state;
      return isVideoContext || isCobrowseContext || isVoiceCallContext;
    },
    // TODO (Gabe) - selected getter is redundant to the selected in state
    selected: state => state.selected,
    conversations: state => {
      const { selected, pastConversations } = state;
      const conversations = [...pastConversations];
      if (selected.id) { conversations.push(selected) }
      return conversations;
    },
    conversationsMessages: state => {
      // Filter out read event messages
      const messages = state.messages.filter(m => get(m, 'payload.event.action') !== 'messages.mark.read');
      const grouped = groupBy(messages, 'conversation_id');
      return grouped;
    },
    agentsMap: state => {
      const agents = get(state, 'selected.agents', []);

      return agents.reduce((map, agent) => {
        map[agent.id] = true;
        return map;
      }, {});
    },
    isAgentUserInConversation: (state, getters, rootState) => {
      const userId = get(rootState, 'agent.profile.id');
      return getters.agentsMap[userId];
    },
    existingItemSuggestions: state => {
      const assetMsgIds = state.suggestedItemHistory.assets.msgIds;
      const documentsMsgIds = state.suggestedItemHistory.documents.msgIds;

      const result = assetMsgIds.reduce((acc, id) => {
        acc[id] = { assets: true, documents: false };
        return acc;
      }, {});

      documentsMsgIds.forEach(id => {
        if (!result[id]) {
          result[id] = { assets: false, documents: true };
        } else {
          result[id].documents = true;
        }
      });

      return result;
    },
    isConversationOpen: state => state.isConversationOpen,
    scope: state => state.scope,
    bot: (_, __, rootState) => rootState.agents.list.find(agent => agent.email === BOT_EMAIL),
  },
  mutations,
  actions,
};
