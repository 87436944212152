export const CHANNELS = {
  WEBCHAT: 'webchat',
  SMS: 'sms',
  FACEBOOK: 'facebook',
  WHATSAPP: 'whatsapp',
};

export const CHANNEL_NAMES_ALL = 'all';
export const CHANNEL_NAMES = [
  CHANNEL_NAMES_ALL,
  'email',
  'twitter',
  'twitter_dm',
  'webchat',
];

export const CLASS_NAMES = [
  'escalation',
  'business',
  'product',
  'customer',
  'greeting',
  'general',
  'other',
];

export const AGENT_STATUS = {
  Available: {
    id: 1,
    label: 'Available',
    class: 'iq-ico-available',
    field: 'available',
  },
  Away: {
    id: 2,
    label: 'Away',
    class: 'iq-ico-away',
    field: 'away',
  },
  Paused: {
    id: 3,
    label: 'Busy',
    class: 'iq-ico-at-capacity',
    field: 'paused',
  },
};

// This is a shadow status of away but takes into consideration Out of office status
export const OUT_OF_OFFICE_STATUS = {
  id: null,
  label: 'Away/Out of Office',
  class: 'iq-ico-away',
  field: 'away-outofoffice',
};

export const FIXED_AGENT_STATUS = {
  available: {
    value: 'available',
    subStatus: {
      none: '',
    }
  },
  paused: {
    value: 'paused',
    subStatus: {
      none: '',
      onVideo: 'on_video',
      inCobrowse: 'in_cobrowse',
      onVideoAndInCobrowse: 'on_video_and_in_cobrowse',
      onVoice: 'on_voice',
      onVoiceAndInCobrowse: 'on_voice_and_in_cobrowse',
    },
  },
  away: {
    value: 'away',
    subStatus: {
      none: '',
      vacation: 'vacation',
    },
  },
};


export const isConversationsPage = () => window.location.pathname === '/conversations' ||
    window.location.pathname === '/conversations/mine';
export const isStateScopeMine = (state) => state.scope === 'mine';
export const isStateChannelAll = (state) => state.channel === 'all';

// Set with an empty array for buildingKitCommands.js
export const BUILDING_KIT_ITEM_TYPES = {
  dialog: [],
  workflow: [],
  command: [],
  response: [],
};

export {
  parse,
  createRolesAccess,
  DEFAULT_BACKGROUND_URL,
  DEFAULT_PICTURE_URL,
  BOT_EMAIL,
  buildProfile,
  defaultProfile,
} from './agent';

export {
  DEFAULT_CHANNEL,
  DEFAULT_KB_MODE,
  DEFAULT_SCOPE,
  getEffectiveStatus,
  isActive,
  isInactive,
  CONVERSATION_LIMIT,
  MESSAGES_LIMIT,
  KB_MODES,
  KB_MODE_TYPES,
  MSG_SUGGESTION_MEMORY_LIMIT,
  DEFAULT_POLL_INTERVAL,
  SIDE_PANEL_TAB_NAMES,
  ATTACHMENT_HISTORY_TYPES,
  DEFAULT_ATTACHMENTS_HISTORY,
  RESOLVED_STATUS,
  IMPORTANCE,
} from './conversation';

export {
  DEFAULT_TEMPLATE_HEADINGS,
  CUSTOMER_NOTES_TEMPLATE_HEADINGS,
  INGESTION_MODES,
  WEBCHAT_TEMPLATE_HEADINGS,
  OUTBOUND_SMS_TEMPLATE_HEADINGS,
  SMS_TEMPLATE_HEADING
} from './ingestion';

export { SYSTEM_KEYS, KEYBOARD_KEYS } from './keyboardKeys';

export {default as SHORTCUT_MENU} from './shortcutMenu';

export {default as STATUS_CODES} from './statusCodes';

export {default as dialogConstants} from './dialogConstants';

export { TEAM_LIMIT } from './pagination';

export {default as network} from './network';

export {
  ALLOWED_FILE_EXTENSIONS,
  DASHBOARD_FILE_ROUTE_PATH,
} from './compliance';

export {default as IMAGE_ACL_TYPES} from './imageAclTypes';

export { LABELS_TOOLTIPS, replaceDuration } from './pulse';

export { QUERY_MAPPING } from './queryMapping';

export {
  PROFILE_EDITOR_TYPE,
  DEFAULT_SYSTEM_LANGUAGE,
  AVAILABLE_LANGUAGES,
  CHAT_ICON_RESOLUTION,
} from './settings';
