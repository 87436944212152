<template lang="pug">
  managementPage.scrollable(title="Teams"
                @fetchData="loadList"
                @openEditor="openEditor"
                :searchPageSize="searchPageSize"
                :filteredSearch="true"
                :showTotals="true"
                :creatable="canView('/settings/teams/new')"
                :pagination="pagination")
    template(v-slot:editor)
      aiq-dialog.add-edit-team-modal(class="team-modal-container"
                                  v-model="teamFormShown"
                                  :title="temporalTeam.id ? 'Edit Team' : 'New Team'"
                                  :show-close="false")
    router-view(v-slot:editor)
    template(v-slot:body)
      aiq-table.teams-scrollbar(:data="items" v-loading="loading")
        aiq-table-column(label="Name", width="200", prop="name", :sortable="true", :sort-method="onSortName", label-class-name="is-bordered")
        aiq-table-column(label="Description", prop="description", label-class-name="is-bordered")
        aiq-table-column(label="Agents", label-class-name="is-bordered" min-width="150")
          template(v-slot:default="scope")
            .tag-list
              aiq-tag(v-for="agent in scope.row.agents" type="info" :key="agent.id") {{ agent.fullName }}
        aiq-table-column(label="Watcher Agents", label-class-name="is-bordered" min-width="150")
          template(v-slot:default="scope")
            .tag-list
              aiq-tag(v-for="agent in scope.row.watcherAgents" type="info" :key="agent.id") {{ agent.fullName }}
        aiq-table-column(label="Keywords", label-class-name="is-bordered", class-name="cell-keywords", min-width="164")
          template(v-slot:default="scope")
            aiq-keyword-list(:list="scope.row.keywords")
        aiq-table-column(v-if="canView('/settings/teams/:number')" label-class-name="is-bordered" width="71")
          template(v-slot:default="scope")
            aiq-button(link type="primary" size="small", @click="openEditor(scope.row)") Edit
</template>

<script>
import get from 'lodash/get';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import { mapState } from 'vuex';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { AGENT_LIMIT, PAGINATION_DEFAULT } from '@/constants/pagination';

const SEARCH_PAGE_SIZE = 25;

@Component({
  components: {
    ManagementPage,
  },
  computed: {
    ...mapState({
      items: state => state.teams.teams.map((team) => {
        const agents = team.agents ? team.agents : [];
        return {
          ...team,
          agents
        };
      }),
      selected: state => state.teams.selected,
    }),
  },
  methods : {
    onSortName(a, b) {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? 1 : -1;
    }
  },
})
class Teams extends Vue {
  pagination = PAGINATION_DEFAULT;
  loading = true;
  temporalTeam = {};
  searchPageSize = SEARCH_PAGE_SIZE;
  teamFormShown = false;

  mounted() {
    this.$store.dispatch('agents/getAgentsList', [{ limit: AGENT_LIMIT }]);

    this.temporalTeam = {
      ...this.$store.getters['teams/teamModel'],
    };
  }

  async loadList({ searchTerm, searchOffset }) {
    const params = {
      limit: this.searchPageSize,
      query: searchTerm,
      offset: searchOffset,
    };

    const response = await this.$store.dispatch('teams/getTeamsList', [
      params,
      searchOffset === 0,
    ]);
    this.loading = false;
    this.pagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
    return response;
  }

  openEditor(record) {
    this.record = {
      ...this.$store.getters['teams/teamModel'],
      ...record,
    };
    this.$store.dispatch('teams/selectTeam', this.record)
      .then(() => {
        const recordId = this.record.id || 'new';
        const path = `${this.$route.path}/${recordId}`;
        this.$router.push({ path });
      });
  }
}
export default toNative(Teams);
</script>

<style lang="scss">
  .tag-list {
    padding: 2px;
    .el-tag.el-tag--info.el-tag--light {
      margin-right: 2px;
      margin-bottom: unset;
    }
  }
</style>
