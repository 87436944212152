<template lang="pug">
    .insight-box
        h3 Selections 
        .dropdown-filter
          segment-select(:customer-segment-filter-options="CustomerSegmentFilterOptions"
                        :segment-alias="segmentAlias"
                        is-select-multiple
                        is-select-collapse-tags
                        @change="onFilterChange"
                        @on-segment-hover="onSegmentHover")
        .insights-chart
          aiq-col(:span="24")
              .selection(v-for="(selection, index) in selections")
                  span.question(v-html="getQuestion(selection)")
                  overview-chart(v-if="selection"
                      :keepChartOnly="true"
                      :chartConfig="chartConfig(selection.data_points, index)"
                      :preLoadedchartData="selectionsChartData(selection.data_points)"
                      yLabel="Count"
                      :formatYTick="intCountFormatter"
                      :axisRotated="true"
                      :barWidth="25"
                      visualization="aiq-category-bar-per-line-chart")
              h3.no-item(v-if="insightSelections && selections.length === 0") No selections available.
  </template>


<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import OverviewChart from '@/components/Chart.vue';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import { intTickFormatter } from '@/pages/Metrics/helpers/formatters';
import PulsePanel from '../common/panel.vue';
import { unicodeToASCII } from '@/libs';

const TOTAL_LABEL = 'TOTAL';
const NO_SELECTION_LABEL = 'No Selection Made';
const TOP_3_QUESTIONS_LIMIT = 3;


const COLORS = [
  '#194696',
  '#097624',
  '#F07E15',
  '#FF6384',
  '#FFBE50',
  '#FFEC71',
  '#D7D9DE',
  '#4671B3',
  '#4BC0C0',
  '#4671b3',
  '#4785b6',
  '#4999ba',
  '#4aacbd',
  '#4bc0c0',
  '#4bc0c6',
];

export default {
  pageResource: '/metrics/insights',
  extends: PulsePanel,
  components: {
    OverviewChart,
    SegmentSelect,
  },
  props: {
    action: {
      type: String,
      default: 'getTimesliceMetrics',
    },
    storeKey: {
      type: String,
      default: 'timeslice',
    },
  },
  data() {
    return {
      selections: [],
      selectedFilters: [],
      segmentAlias: '',
      intCountFormatter: intTickFormatter,
      insightSelections: null,
    };
  },
  computed: {
    ...mapGetters({
      CustomerSegmentFilterOptions: 'configs/segments',
    }),
  },
  mounted() {
    this.$store.dispatch('configs/getCustomerSegments');
  },
  methods: {
    getQuestion(dataset) {
      return unicodeToASCII(get(dataset, 'data_points.0.question', ''));
    },
    selectionsChartData(selectionData) {
      return this.convertToChartFormat(selectionData);
    },
    onFilterChange(chosenFilters) {
      this.selectedFilters = chosenFilters;
      this.loadMetrics();
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
    convertToChartFormat(selectionData) {
      return {
        dataset: selectionData
          .filter(dp => dp.option !== TOTAL_LABEL)
          .map((item, index) => {
            return {
              metric: item.option,
              data_points: [
                {
                  x: index,
                  ys: [
                    {
                      metric_category: 'count',
                      value: item.frequency,
                    },
                  ],
                },
              ],
            };
          }),
      };
    },
    chartConfig(selectionData, index) {
      const lines = selectionData.reduce((acc, item) => {
        acc[item.option] = {
          categoryLabel: item.option,
        };
        return acc;
      }, {});
      return {
        name: 'selection_distribution',
        title: 'selection distribution',
        lines,
        linesColor : COLORS[index],
      };
    },
    transformData(input) {
      const result = [];
      input?.dataset.forEach(datasetItem => {
        const dataPoints = datasetItem.data_points;
        const top3Questions = this.sortTop3Questions(dataPoints);

        Object.keys(top3Questions).map(key => {
          const datapoint = top3Questions[key].replies.map(reply=>{
            return {
              question: top3Questions[key]?.question,
              option: reply?.reply || NO_SELECTION_LABEL,
              frequency: reply?.value,
            };
          });
          const totalReplies = top3Questions[key].replies.reduce(
            (accumulator, question) => accumulator + question?.value,
            0,
          );
          result.push({
            label: datasetItem.label,
            data_points: datapoint,
            totalReplies
          });
        });
      });
      return result;
    },
    sortTop3Questions(dataPoints){
      const sortedQuestions = Object.entries(dataPoints).sort(([, a], [, b]) => {
        return Number(b.total) - Number(a.total);
      }).slice(0, TOP_3_QUESTIONS_LIMIT);

      const sortedQuestionsObj = sortedQuestions.reduce((acc, [key, value]) => {
        acc[key.toString()] = value;
        return acc;
      }, {}); 

      return sortedQuestionsObj;
    },
    loadMetrics(){
      const activeFilters = this.selectedFilters?.map(item=> item?.name);
      const metrics = this.insightSelections;
      const questions = this.transformData(metrics).filter(metric =>
        activeFilters?.length ? activeFilters.includes(metric?.label) : true
      );
      this.selections = this.TransformSelections(questions);
    },
    TransformSelections(selections) {
      const sortedQuestions = selections.sort((a, b) => {
        return b.totalReplies - a.totalReplies;
      });
      return sortedQuestions.slice(0, TOP_3_QUESTIONS_LIMIT);
    }
  },
  watch: {
    metrics: {
      handler(newMetrics) {
        this.insightSelections = newMetrics.data?.dataset?.find(
          item => item.metric === 'insight_selections'
        );
        this.loadMetrics();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/aiq-mixins.scss";
.insight-box {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
}
.insights-chart {
  height: calc(100vh - 285px);
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  .selection {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 15px 5px;
    margin-bottom: 15px;
    border: 1px solid #e4e7ed;
  }
  .question {
    font-size: 18px;
    color: #777777;
    margin-left: 10px;
  }
}
.dropdown-filter {
  width: 100%;
  margin-bottom: 15px;
}
</style>
